/* eslint no-tabs: "off", indent: "off" */

import {
	BEGIN_MIDDLE_ISOLATION_PIE,
	BEGIN_ISOLATION_TABLE,
	BEGIN_MIDDLE_ISOLATION_LEGEND,
	BEGIN_TOP_ISOLATION_PIE,
	BEGIN_TOP_ISOLATION_LEGEND,
	CLOSE_INFO_TOOLTIP,
	CLOSE_METHODOLOGY,
	CLOSE_TAG_TOOLTIP,
	END_MIDDLE_ISOLATION,
	END_TOP_ISOLATION,
	OPEN_INFO_TOOLTIP,
	OPEN_METHODOLOGY,
	OPEN_TAG_TOOLTIP,
	OPEN_INFO_TOOLTIP_WITH_TEXT,
	SET_BROWSER_TYPE,
	SET_MIDDLE_ISOLATION_FLAG,
	SET_TOP_ISOLATION_FLAG,
} from "actions/constants.js";

// import { getScrollbarSize } from "lib/helpers.js";

const initialState = {
	// Try not to use this. Do feature detection whenever possible
	browserType: "", // string: "blink", "chrome", "edge", "ie", "safari", "firefox", "opera", or undefined

	darkUI: true,
	infoTooltipBullets: [],
	infoTooltipHeader: "",
	infoTooltipOpen: false,
	infoTooltipPosition: { top: 0, left: 0 },
	isolatedId: "",
	isolatedIdLegend: "",
	isolatedIdTable: "",
	isolationFlag: "",
	methodologyOpen: false,
	scrollbarSize: false, // false so it only gets set once
	showFocus: false, // show blue outline for focus?
	tagTooltipColor: "",
	tagTooltipPosition: { top: 0, left: 0 },
	tagTooltipOpen: false,
	tagTooltipText: "",
	topIsolatedId: "",
	topIsolatedIdLegend: "",
	topIsolatedSide: "",
	topIsolationFlag: "",
	windowSize: { height: 0, width: 0 },
};

const uiReducer = (state = initialState, action) => {
	switch (action.type) {
		// UI
		case "setWindowSize":
			return Object.assign({}, state, {
				windowSize: action.windowSize,
				// scrollbarSize: state.scrollbarSize ? state.scrollbarSize : getScrollbarSize(),
			});
		case SET_BROWSER_TYPE:
			return Object.assign({}, state, {
				browserType: action.browserType,
			});
		case "putFocusBack":
			return Object.assign({}, state, {
				showFocus: true,
			});
		case CLOSE_TAG_TOOLTIP:
			return Object.assign({}, state, {
				tagTooltipOpen: false,
			});
		case OPEN_TAG_TOOLTIP: {
			const { position, tag } = action;
			return Object.assign({}, state, {
				tagTooltipColor: tag.color,
				tagTooltipPosition: position,
				tagTooltipOpen: true,
				tagTooltipText: tag.name,
			});
		}

		case CLOSE_INFO_TOOLTIP:
			return Object.assign({}, state, {
				infoTooltipOpen: false,
			});
		case OPEN_INFO_TOOLTIP: {
			const { position, slice } = action;
			return Object.assign({}, state, {
				infoTooltipBullets: slice.info || [],
				infoTooltipHeader: slice.header || slice.name,
				infoTooltipOpen: !!slice.info || !!slice.header,
				infoTooltipPosition: position,
			});
		}
		case OPEN_INFO_TOOLTIP_WITH_TEXT: {
			const { position, text } = action;
			return Object.assign({}, state, {
				infoTooltipBullets: [],
				infoTooltipHeader: text,
				infoTooltipOpen: true,
				infoTooltipPosition: position,
			});
		}

		case CLOSE_METHODOLOGY:
			return Object.assign({}, state, {
				methodologyOpen: false,
			});
		case OPEN_METHODOLOGY: {
			return Object.assign({}, state, {
				methodologyOpen: true,
			});
		}

		// ------ Isolation ------
		case SET_MIDDLE_ISOLATION_FLAG: {
			return Object.assign({}, state, {
				isolationFlag: action.isolationFlag,
			});
		}
		case BEGIN_MIDDLE_ISOLATION_PIE: {
			const { isolationFlag } = state;
			return Object.assign({}, state, {
				isolatedId: action.isolate === isolationFlag ? action.isolate : "",
				isolatedIdTable: action.isolate === isolationFlag ? action.isolate : "",
				isolatedIdLegend: action.isolate === isolationFlag ? action.isolate : "",
			});
		}
		case BEGIN_ISOLATION_TABLE: {
			const { isolationFlag } = state;
			return Object.assign({}, state, {
				isolatedId: action.isolate === isolationFlag ? action.isolate : "",
				isolatedIdLegend: action.isolate === isolationFlag ? action.isolate : "",
			});
		}
		case BEGIN_MIDDLE_ISOLATION_LEGEND: {
			const { isolationFlag } = state;
			return Object.assign({}, state, {
				isolatedId: action.isolate === isolationFlag ? action.isolate : "",
				isolatedIdTable: action.isolate === isolationFlag ? action.isolate : "",
			});
		}
		case END_MIDDLE_ISOLATION: {
			const { isolationFlag } = state;
			return Object.assign({}, state, {
				isolatedId: !isolationFlag ? "" : state.isolatedId,
				isolatedIdTable: !isolationFlag ? "" : state.isolatedIdTable,
				isolatedIdLegend: !isolationFlag ? "" : state.isolatedIdLegend,
			});
		}

		case SET_TOP_ISOLATION_FLAG: {
			return Object.assign({}, state, {
				topIsolationFlag: action.isolationFlag,
				topIsolatedSide: action.side || state.topIsolatedSide,
			});
		}
		case BEGIN_TOP_ISOLATION_PIE: {
			const { topIsolationFlag } = state;
			return Object.assign({}, state, {
				topIsolatedId: action.isolate === topIsolationFlag ? action.isolate : "",
				topIsolatedIdLegend: action.isolate === topIsolationFlag ? action.isolate : "",
			});
		}
		case BEGIN_TOP_ISOLATION_LEGEND: {
			const { topIsolationFlag } = state;
			return Object.assign({}, state, {
				topIsolatedId: action.isolate === topIsolationFlag ? action.isolate : "",
			});
		}
		case END_TOP_ISOLATION: {
			const { topIsolationFlag } = state;
			return Object.assign({}, state, {
				topIsolatedId: !topIsolationFlag ? "" : state.topIsolatedId,
				topIsolatedIdLegend: !topIsolationFlag ? "" : state.topIsolatedIdLegend,
			});
		}

		default:
			return state;
	}
};

export default uiReducer;

import React from "react";
import PropTypes from "prop-types";

import { GraphMain } from "@exovera/graph";
// import { Select } from "@exovera/select";
import Pie from "./Pie.jsx";
import Table from "@exovera/table";

import vars from "@exovera/narrative-tracker-variables";
import styled from "styled-components";
import { clearAfter } from "@exovera/style-mixins";

// import { shadeColor } from "lib/helpers.js";
import { Tabs, TripleToggle } from "components";
import {
	addSwatch,
	formatNumber,
	HighlightActiveRow,
	paddingLeft,
	transformRate,
	rateOfChangeLabel7,
	rateOfChangeLabel30,
	volumeLabel,
} from "lib/tableTransforms.js";
import { log } from "lib/helpers.js";

// ------- Styles -------
const Root = styled.section`
	position: relative;
	padding: ${(props) => (props.morePadding ? 120 : 65)}px 4% 0;
	background-color: ${vars.charcoal_1_2};
	${clearAfter()};
	overflow: hidden;
	@media all and (max-width: 1000px) {
		padding-top: 65px;
	}
	@media all and (max-width: 680px) {
		padding: 45px 2% 0;
	}
`;
const MaxWidth = styled.div`
	position: relative;
	max-width: 1200px;
	margin: 0 auto;
	padding-bottom: 80px;
`;
const ToggleBox = styled.div`
	position: relative;
	width: 302px;
	max-width: 100%;
	margin: 0 auto 100px;
	visibility: ${(props) => (props.hide ? "hidden" : "visible")};
	@media all and (max-width: 1000px) {
		margin-bottom: 60px;
	}
	@media all and (max-width: 900px) {
		margin-bottom: 40px;
	}
`;
const GraphBox = styled.div`
	float: ${(props) => (props.horizontalLayout ? "left" : "none")};
	width: ${(props) => (props.horizontalLayout ? 60 : 100)}%;
	margin-top: ${(props) => (props.horizontalLayout ? 0 : 130)}px;
	padding-top: 7px;
	background-color: ${vars.charcoal_1};
	border-radius: 4px;
	@media all and (max-width: 560px) {
		width: 108%;
		margin-left: -4%;
		border-radius: 0;
	}
`;
const GraphTitle = styled.p`
	position: relative;
	/* Pop this out of the box, but keep it centered. Accounts for GraphBox's padding */
	top: ${(props) => (props.horizontalLayout ? -50 : -40)}px;
	${vars.mainfont};
	font-size: 16px;
	text-align: center;
	color: ${vars.sky_1};
`;
const DonutBox = styled.div`
	float: right;
	width: ${(props) => (props.fullWidth ? 100 : 40)}%;
	margin-bottom: -40px;
`;

const TableBox = styled.div`
	clear: both;
	padding-top: 80px;
	max-width: 1200px;
	margin: 0 auto;
`;

const TabsBox = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	@media all and (max-width: 1200px) {
		left: -4.5%;
		right: -4.5%;
	}
`;

// ------- Component -------
const Middle = (props) => {
	const {
		activeTabData,
		beginIsolationTable,
		beginMiddleIsolationPie,
		bottomTabs,
		browserType,
		clickBottomTab,
		currentDateObj,
		currentFilterObj,
		dataOptions,
		dateRangeString,
		endMiddleIsolation,
		isolatedId,
		isolatedIdLegend,
		isolatedIdTable,
		middlePie,
		middleGraph,
		middleTable,
		selectedDataOption,
		setMiddleData,
		showFocus,
		toggleInfoTooltip,
		topToggleActive,
		visibleAnnotations,
		windowSize,
	} = props;
	log(["Middle - middleTable", middleTable]);

	// JavaScript adjustment to sync with props changes
	const mobileSize = windowSize.width < 380;
	const smallTableHeaders = windowSize.width < 700;
	const bigEnoughForGraph = windowSize.width > 520;
	const horizontalLayout = windowSize.width > 1000;
	const smallSize = windowSize.width < 900;

	const dateString = currentDateObj.id === "history" ? "Historically" : `Over the ${currentDateObj.name}`;
	const filterString = currentFilterObj ? ` Across ${currentFilterObj.name}` : "";

	return (
		<Root morePadding={topToggleActive === "overall"}>
			<MaxWidth>
				{topToggleActive !== "overall" && (
					<ToggleBox hide={topToggleActive === "overall"}>
						<TripleToggle
							left={dataOptions.left}
							center={dataOptions.center}
							right={dataOptions.right}
							activeId={selectedDataOption.id}
							backgroundColor={vars.darkGrey_4}
							click={setMiddleData}
							buttonWidth={100}
							height={28}
							showFocus={showFocus}
						/>
					</ToggleBox>
				)}

				{!horizontalLayout && (
					<Pie
						beginMiddleIsolationPie={beginMiddleIsolationPie}
						currentDateObj={currentDateObj}
						currentFilterObj={currentFilterObj}
						dateRangeString={dateRangeString}
						endMiddleIsolation={endMiddleIsolation}
						mobileSize={mobileSize}
						isolatedId={isolatedId}
						isolatedIdLegend={isolatedIdLegend}
						middlePie={middlePie}
						showFocus={showFocus}
						smallSize={smallSize}
						toggleInfoTooltip={toggleInfoTooltip}
					/>
				)}

				{bigEnoughForGraph &&
					browserType !== "ie" && (
						<GraphBox horizontalLayout={horizontalLayout}>
							<GraphTitle horizontalLayout={horizontalLayout}>{`${middleGraph.title} ${dateString}${filterString}`}</GraphTitle>
							<GraphMain
								allowFeatured
								annotations={visibleAnnotations}
								browserType={"blink"}
								colorBlindMode={false}
								// browserType={browserType}
								// colorBlindMode={colorBlindMode}
								// dataTypeActive={{ name: "Article Count", id: "articleCount" }}
								darkUI
								disableZoom
								graphHeight={200}
								graphId="narrativeTrackerLite"
								hideBoxShadow
								hideControls
								hideLegend
								height={380}
								highlightLine={isolatedId}
								lines={middleGraph.lines}
								loadingTimeseries={false}
								showFocus={showFocus}
								timezone={{
									name: "Eastern Standard Time",
									offsetDisplay: "-5:00",
									offset: -300, // in minutes
								}}
								// userControlledAggregation
								yAxisTitle="Relative Media Share (Volume)"
							/>
						</GraphBox>
					)}

				{horizontalLayout && (
					<DonutBox fullWidth={browserType === "ie"}>
						<Pie
							beginMiddleIsolationPie={beginMiddleIsolationPie}
							currentDateObj={currentDateObj}
							currentFilterObj={currentFilterObj}
							dateRangeString={dateRangeString}
							endMiddleIsolation={endMiddleIsolation}
							mobileSize={mobileSize}
							isolatedId={isolatedId}
							isolatedIdLegend={isolatedIdLegend}
							middlePie={middlePie}
							showFocus={showFocus}
							smallSize={smallSize}
							toggleInfoTooltip={toggleInfoTooltip}
						/>
					</DonutBox>
				)}

				<TableBox>
					<Table
						columns={[
							{
								name: mobileSize ? "Narratives" : middleTable.subjectType,
								id: "name",
								width: 5,
								fixed: false,
								disableSort: true,
								transform: addSwatch,
								headerTransform: paddingLeft,
							},
							{
								name: "7 Days",
								id: "rate7",
								width: 105,
								fixed: true,
								minWidth: 470,
								transform: transformRate,
								reverseSort: true,
								ariaLabelTransform: rateOfChangeLabel7,
								info: "Rate of change over the last 7 days",
							},
							{
								name: "30 Days",
								id: "rate30",
								width: 110,
								fixed: true,
								minWidth: 585,
								transform: transformRate,
								reverseSort: true,
								ariaLabelTransform: rateOfChangeLabel30,
								info: "Rate of change over the last 30 days",
							},
							{
								name: smallTableHeaders ? "Total" : "Total Volume",
								id: "total",
								width: smallTableHeaders ? 70 : 140,
								fixed: true,
								// minWidth: 585,
								transform: formatNumber,
								reverseSort: true,
								ariaLabelTransform: volumeLabel,
								info: smallTableHeaders && "Total volume of articles published",
							},
						]}
						rows={middleTable.rows || []}
						context={{ activeTabData, isolatedId: isolatedIdTable }}
						clickRow={clickBottomTab}
						defaultSort="total"
						height={false} // no scrollbars
						hoverRow={beginIsolationTable}
						hoverRowEnd={endMiddleIsolation}
						RowTransform={HighlightActiveRow}
						showFocus={showFocus}
						// --- colors ---
						altBackgroundColor={vars.charcoal_2}
						altBackgroundColorHover={vars.charcoal_2_5}
						backgroundColor={vars.charcoal_1}
						backgroundColorHover={vars.charcoal_1_2}
						checkboxColor={"#000"}
						checkboxColorHover="#000"
						checkmarkColor={vars.cerulean_1}
						footerBackgroundColor={vars.charcoal_2_5}
						footerTextColor={vars.sky_1}
						footerHighlightTextColor={vars.sky_0_5}
						footerHighlightColor="#000000"
						loaderColor={vars.cerulean_1}
						tableBoxColor={vars.charcoal_2}
						textColor={vars.sky_1}
						textColorHover={vars.sky_0_5}
						headerColor={vars.sky_1}
						headerColorHover={vars.sky_0_5}
					/>
				</TableBox>

				<TabsBox>
					<Tabs
						clickTab={clickBottomTab}
						maxWidth={300}
						minWidth={220}
						showFocus={showFocus}
						tabs={bottomTabs}
						windowSize={windowSize}
						// colors
						activeTextColor={vars.sky_1}
						hoverColor={vars.sky_1}
						textColor={vars.sky_2}
					/>
				</TabsBox>
			</MaxWidth>
		</Root>
	);
};

Middle.defaultProps = {};

Middle.propTypes /* remove-proptypes */ = {
	activeTabData: PropTypes.shape({
		active: PropTypes.bool,
		color: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
	beginMiddleIsolationPie: PropTypes.func.isRequired,
	beginIsolationTable: PropTypes.func.isRequired,
	browserType: PropTypes.string.isRequired,
	bottomTabs: PropTypes.arrayOf(
		PropTypes.shape({
			active: PropTypes.bool,
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			color: PropTypes.string,
		}).isRequired
	).isRequired,
	clickBottomTab: PropTypes.func.isRequired,
	currentDateObj: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		// startDate: PropTypes.number.isRequired,
		// endDate: PropTypes.number.isRequired,
		// totalDays: PropTypes.number.isRequired,
	}).isRequired,
	currentFilterObj: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
		}),
	]).isRequired,
	dataOptions: PropTypes.shape({
		left: PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		}).isRequired,
		center: PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		}).isRequired,
		right: PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	// dataOptions: PropTypes.arrayOf(
	// 	PropTypes.shape({
	// 		name: PropTypes.string.isRequired,
	// 		id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
	// 		indent: PropTypes.number,
	// 		active: PropTypes.bool,
	// 	})
	// ).isRequired,
	dateRangeString: PropTypes.string.isRequired,
	endMiddleIsolation: PropTypes.func.isRequired,
	isolatedId: PropTypes.string.isRequired,
	isolatedIdLegend: PropTypes.string.isRequired,
	isolatedIdTable: PropTypes.string.isRequired,
	middlePie: PropTypes.shape({
		slices: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				value: PropTypes.number.isRequired,
				color: PropTypes.string.isRequired,
			}).isRequired
		).isRequired,
		altTitle: PropTypes.string.isRequired,
		shade: PropTypes.string,
		donut: PropTypes.bool,
		shortLegend: PropTypes.bool,
	}).isRequired,
	middleGraph: PropTypes.shape({
		lines: PropTypes.arrayOf(
			PropTypes.shape({
				color: PropTypes.shape({
					colorblindstripes: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
					dark: PropTypes.string,
					light: PropTypes.string,
				}).isRequired,
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				additionalLegendText: PropTypes.string,
				parent: PropTypes.string,
				rangeEnd: PropTypes.number.isRequired,
				rangeStart: PropTypes.number.isRequired,
				timeseries: PropTypes.object.isRequired,
				type: PropTypes.string, // used with percentType
				weight: PropTypes.number,
			}).isRequired
		).isRequired,
		title: PropTypes.string.isRequired,
	}).isRequired,
	middleTable: PropTypes.shape({}).isRequired,
	selectedDataOption: PropTypes.shape({
		name: PropTypes.string.isRequired,
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
		color: PropTypes.string,
	}).isRequired,
	setMiddleData: PropTypes.func.isRequired,
	showFocus: PropTypes.bool.isRequired,
	toggleInfoTooltip: PropTypes.func.isRequired,
	topToggleActive: PropTypes.bool.isRequired,
	visibleAnnotations: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			date: PropTypes.number.isRequired,
			narratives: PropTypes.arrayOf(PropTypes.string).isRequired,
			title: PropTypes.string.isRequired,
		})
	).isRequired,
	windowSize: PropTypes.shape({
		height: PropTypes.number.isRequired,
		width: PropTypes.number.isRequired,
	}).isRequired,
};

export default Middle;

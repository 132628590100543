import React, { Fragment } from "react";
import PropTypes from "prop-types";
// import _ from "underscore";

import vars, { focusStyle } from "@exovera/narrative-tracker-variables";
import styled from "styled-components";
// import { smallCaps } from "@exovera/style-mixins";

import ExplodedPie from "@exovera/exploded-pie";

// import { LeftArrow, RightArrow } from "components/Icons";
import { TripleToggle } from "components";

// ------- Styles -------
const TopRoot = styled.section`
	background-color: ${vars.charcoal_1};
	padding: 0 3% 50px;
	border-radius: 4px 4px 0 0;
	overflow: hidden;
`;
const StaticToggle = styled.div`
	visibility: ${(props) => (props.show ? "visible" : "hidden")};
	margin: 50px auto 0;
	width: ${(props) => props.theWidth}px;
	z-index: 101;
`;
const Title = styled.h1`
	margin: 50px 0;
	font-size: 18px;
	${vars.mainfont};
	text-align: center;
	line-height: 130%;
	color: ${vars.sky_1};
	@media all and (max-width: 680px) {
		margin-bottom: 20px;
	}
`;
const SubTitle = styled.p`
	margin-top: ${(props) => (props.sneakDown ? 70 : 5)}px;
	margin-bottom: ${(props) => (props.sneakDown ? -40 : 0)}px;
	font-size: 14px;
	${vars.mainfont};
	text-align: center;
	color: ${vars.sky_1};
	@media all and (max-width: 680px) {
		margin: 0;
	}
`;

// ------- Component -------
const Top = (props) => {
	const {
		beginTopIsolationPie,
		browserType,
		centerPie,
		clickTopToggle,
		currentDateObj,
		currentFilterObj,
		dateRangeString,
		endTopIsolation,
		leftPie,
		rightPie,
		showFocus,
		showStaticToggle,
		toggleInfoTooltip,
		topIsolatedId,
		topIsolatedIdLegend,
		topIsolatedSide,
		topToggle,
		topToggleActive,
		totalArticles,
		windowSize,
	} = props;

	// JavaScript adjustment to sync with props changes
	const mobileSize = windowSize.width < 380;
	const smallSize = windowSize.width < 900;

	const dateString = currentDateObj.id === "history" ? "Historically" : `Over the ${currentDateObj.name}`;
	const filterString = currentFilterObj ? ` Across ${currentFilterObj.name}` : "";

	return (
		<TopRoot>
			<StaticToggle theWidth={mobileSize ? 284 : 302} show={showStaticToggle}>
				<TripleToggle
					left={topToggle.left}
					center={topToggle.right}
					right={topToggle.both}
					activeId={topToggleActive}
					click={clickTopToggle}
					buttonWidth={mobileSize ? 94 : 100}
					showFocus={showFocus}
				/>
			</StaticToggle>

			<Title>{`${topToggleActive !== "overall" ? `${centerPie.title} Narratives` : centerPie.title} ${dateString}${filterString}`}</Title>
			<SubTitle sneakDown={smallSize}>{centerPie.subTitle}</SubTitle>

			<ExplodedPie
				windowWidth={windowSize.width}
				centerPie={centerPie}
				leftPie={leftPie}
				rightPie={rightPie}
				contextString={`${totalArticles.toLocaleString()} total articles`}
				dateRangeString={dateRangeString}
				browserType={browserType}
				showFocus={showFocus}
				opposeStripeId="svgOpposeStripe"
				isolatedId={topIsolatedId}
				isolatedIdLegend={topIsolatedIdLegend}
				isolatedSide={topIsolatedSide}
				beginIsolation={beginTopIsolationPie}
				endIsolation={endTopIsolation}
				legendItemClick={toggleInfoTooltip}
			/>
		</TopRoot>
	);
};

Top.defaultProps = {};

Top.propTypes /* remove-proptypes */ = {
	beginTopIsolationPie: PropTypes.func.isRequired,
	browserType: PropTypes.string.isRequired,
	centerPie: PropTypes.shape({
		slices: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				value: PropTypes.number.isRequired,
				color: PropTypes.string.isRequired,
			}).isRequired
		).isRequired,
		title: PropTypes.string.isRequired,
		subTitle: PropTypes.string.isRequired,
		donut: PropTypes.bool,
	}).isRequired,
	clickTopToggle: PropTypes.func.isRequired,
	currentDateObj: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		// startDate: PropTypes.number.isRequired,
		// endDate: PropTypes.number.isRequired,
		// totalDays: PropTypes.number.isRequired,
	}).isRequired,
	currentFilterObj: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
		}),
	]).isRequired,
	dateRangeString: PropTypes.string.isRequired,
	endTopIsolation: PropTypes.func.isRequired,
	leftPie: PropTypes.shape({
		slices: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				value: PropTypes.number.isRequired,
				color: PropTypes.string.isRequired,
			}).isRequired
		).isRequired,
		title: PropTypes.string.isRequired,
		shade: PropTypes.string,
		donut: PropTypes.bool,
		shortLegend: PropTypes.bool,
	}).isRequired,
	rightPie: PropTypes.shape({
		slices: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				value: PropTypes.number.isRequired,
				color: PropTypes.string.isRequired,
			}).isRequired
		).isRequired,
		title: PropTypes.string.isRequired,
		shade: PropTypes.string,
		donut: PropTypes.bool,
		shortLegend: PropTypes.bool,
	}).isRequired,
	showFocus: PropTypes.bool.isRequired,
	showStaticToggle: PropTypes.bool.isRequired,
	toggleInfoTooltip: PropTypes.func.isRequired,
	topIsolatedId: PropTypes.string.isRequired,
	topIsolatedIdLegend: PropTypes.string.isRequired,
	topIsolatedSide: PropTypes.string.isRequired,
	topToggle: PropTypes.shape({
		left: PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		}).isRequired,
		right: PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		}).isRequired,
		both: PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	topToggleActive: PropTypes.string.isRequired,
	totalArticles: PropTypes.number.isRequired,
	windowSize: PropTypes.shape({
		height: PropTypes.number.isRequired,
		width: PropTypes.number.isRequired,
	}).isRequired,
};

export default Top;

import React, { useRef } from "react";
import PropTypes from "prop-types";
// import _ from "underscore";

import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, FacebookIcon, LinkedinIcon, TwitterIcon } from "react-share";

import vars, { focusStyle, solidButton } from "@exovera/narrative-tracker-variables";
import styled from "styled-components";
// import { smallCaps } from "@exovera/style-mixins";

// ------- Styles -------
const FooterRoot = styled.div`
	position: relative;
	padding: 30px;
	text-align: center;
`;
const Text = styled.p`
	margin: 20px auto 25px;
	text-align: center;
	line-height: 130%;
	color: ${vars.sky_1};
	${vars.mainfont};
`;
const ContactUsFooter = styled.a`
	display: inline-block;
	${solidButton("#6f297b", "#5e1e69", "#ffffff")};
	${vars.mainfontBold};
	width: 145px;
	/* margin: 0 auto; */
	border: 1px solid transparent;
	${(props) => props.showFocus && focusStyle()};
`;

const ShareButtons = styled.div`
	text-align: center;
	margin-top: 20px;
`;
const Button = styled.div`
	display: inline-block;
	margin: 0 10px;
	opacity: 0.8;
	&:hover {
		opacity: 1;
	}
	button {
		border: 1px solid transparent !important;
		outline: none;
		height: 30px;
		width: 30px;
		&:focus {
			border: 1px solid ${(props) => (props.showFocus ? vars.cerulean_1 : "transparent")}!important;
			outline: none;
			border-radius: 20px;
			box-shadow: 0 0 22px 0px ${(props) => (props.showFocus ? vars.cerulean_1 : "transparent")};
		}
	}
`;

// ------- Component -------
const Footer = (props) => {
	const { showFocus, socialTitle, socialText, socialHashtags, socialTwitter } = props;
	// console.log("Footer", props);
	return (
		<FooterRoot>
			<Text>For additional narratives, comparative views, and more:</Text>
			<ContactUsFooter href="http://www.exovera.com/" target="_blank" showFocus={showFocus}>
				Contact Us
			</ContactUsFooter>

			<ShareButtons>
				<Button showFocus={showFocus}>
					<FacebookShareButton url={window.location.href} quote={socialText} hashtag={`#${socialHashtags[0]}`}>
						<FacebookIcon size={28} iconFillColor={vars.black_3} bgStyle={{ fill: vars.sky_1 }} round />
					</FacebookShareButton>
				</Button>
				<Button showFocus={showFocus}>
					<LinkedinShareButton url={window.location.href} title={socialTitle} summary={socialText}>
						<LinkedinIcon size={28} iconFillColor={vars.black_3} bgStyle={{ fill: vars.sky_1 }} round />
					</LinkedinShareButton>
				</Button>
				<Button showFocus={showFocus}>
					<TwitterShareButton
						url={window.location.href}
						title={socialTitle}
						via={socialText}
						hashtags={socialHashtags}
						related={socialTwitter}
					>
						<TwitterIcon size={28} iconFillColor={vars.black_3} bgStyle={{ fill: vars.sky_1 }} round />
					</TwitterShareButton>
				</Button>
			</ShareButtons>
		</FooterRoot>
	);
};

Footer.defaultProps = {};

Footer.propTypes /* remove-proptypes */ = {
	showFocus: PropTypes.bool.isRequired,
	socialTitle: PropTypes.string,
	socialText: PropTypes.string,
	socialHashtags: PropTypes.arrayOf(PropTypes.string),
	socialTwitter: PropTypes.arrayOf(PropTypes.string),
};

export default Footer;

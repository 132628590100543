/* eslint-disable react/no-unescaped-entities */
import React from "react";
// import PropTypes from "prop-types";
import _ from "underscore";

import vars from "@exovera/narrative-tracker-variables";
import styled from "styled-components";
import { clearAfter, smallCaps } from "@exovera/style-mixins";

// ------- Styles -------
const Root = styled.section`
	padding: 50px;
`;
const Title = styled.h1`
	${smallCaps(vars.mainfontBold)};
	margin-bottom: 35px;
	font-size: 20px;
	text-align: center;
	color: ${vars.sky_2};
`;
const SectionTitle = styled.h2`
	${vars.mainfontLight};
	margin: 50px 0 10px;
	text-decoration: underline;
	text-decoration-color: ${vars.seal_3};
	font-size: 20px;
	color: ${vars.sky_2};
`;
const Text = styled.p`
	clear: both;
	${vars.mainfont};
	color: ${(props) => (props.subHeader ? vars.sky_2 : vars.sky_1)};
	font-size: 14px;
	text-decoration: ${(props) => (props.underline ? "underline" : "none")};
	font-style: ${(props) => (props.italic ? "italic" : "none")};
	line-height: 150%;
	text-align: ${(props) => (props.center ? "center" : "left")};
	margin-bottom: ${(props) => (props.subHeader ? 10 : 30)}px;
	a {
		text-decoration: underline;
		color: ${vars.cerulean_2};
	}
	a:hover {
		text-decoration: underline;
		color: ${vars.cerulean_1};
	}
`;
const Bold = styled.span`
	${vars.mainfontBold};
`;

const Accuracy = styled.div`
	padding: 20px;
	${clearAfter()};
`;
const AccuracyHeader = styled.p`
	${vars.mainfont};
	color: ${vars.sky_1};
	font-size: 15px;
	text-decoration: underline;
	text-align: center;
	margin-bottom: 15px;
`;
const Topic = styled.div`
	float: left;
	width: 48%;
	margin: 0 1% 35px;
`;
const TopicName = styled.div`
	${smallCaps(vars.mainfontBold)};
	margin: 10px 0 10px;
	text-align: center;
	font-size: 16px;
	color: ${vars.sky_2};
`;
const NarrativeList = styled.ul``;
const Narrative = styled.li`
	position: relative;
	margin-bottom: 5px;
	padding: 5px 50px 5px 10px;
	background-color: ${vars.charcoal_1};
	color: ${vars.sky_1};
	${vars.mainfont};
	font-size: 14px;
`;
const Score = styled.span`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	padding-top: 5px;
	font-size: 12px;
	width: 40px;
	text-align: center;
	background-color: ${vars.charcoal_3};
	color: ${vars.sky_0_5};
	${vars.mainfontBold};
`;

// ------- Component -------
const Methodology = (props) => {
	const { topics } = props;
	// console.log("Methodology", props);
	return (
		<Root>
			<Title>Media Narratives: 2020 Presidential Candidates</Title>
			<SectionTitle>I. About</SectionTitle>
			<Text>
				This page is a live, limited public demonstration of the Exovera Narratives platform. Exovera Narratives measures the degree to
				which media content contains various narratives. It uses machine learning AI to classify content by narrative, and measures audience
				reaction to that content on social media. It works by media market, aggregated at the international, national, and all the way down
				to local levels (state, county, DMA).
			</Text>
			<Text>
				The narratives selected here represent some of the core narratives Exovera has detected in the US-based media about Donald Trump and
				Joe Biden. It tracks the number of articles published each day containing the various narratives used by supporters and opponents of
				the two candidates, and it provides links to those articles receiving the most attention on social media. The analytic results are
				based on content about either candidate aggregated from a persistent set of over three-thousand US media outlets.
			</Text>
			<Text>
				The full Exovera Narratives platform provides a suite of advanced analytical tools and tracks a wide range of narratives around the
				world, including the hundreds of narratives related to current US political issues, such as health care, immigration, guns, the
				media, the economy, trade policy, taxes, and much more.
			</Text>
			<Text underline>Narratives are central to political campaigns and political outcomes</Text>
			<Text>
				Exovera thinks of "narratives" as underlying and persistent stories about the world based on what people experience and what they
				are taught. Consciously, or subconsciously, humans use narrative—and not necessarily logic--to quickly processinformation, make
				sense of, and function in the world ("narrative cognition"). Different narratives can explain how people may see and react to the
				same set of facts or events in completely different ways.
			</Text>
			<Text>
				Successful campaigns often work by leveraging and reinforcing narratives that serve the interests of a campaign. The examples here
				show how simplistic some of these can be. Issues (and candidates) are often reduced to caricatures: immigration either "undermines"
				or "strengthens" the US, and political candidates throughout the world are "reformers," "outsiders," or "corrupted," "part of the
				establishment."
			</Text>
			<Text>
				Exovera Narratives helps customers understand how these narratives spread and provides a unique way to quantify their impact on
				audiences over time in near-real time (and possibly even forecast trends and events). Understanding how underlying narratives shape
				political discourse has never been more important.
			</Text>
			<Text>
				Please contact <a href="mailto:service@exovera.com">service@exovera.com</a> for more information or to schedule a demonstration of
				the full narratives platform.
			</Text>
			<SectionTitle>II. How Exovera Narratives Works:</SectionTitle>
			<Text>Exovera trains machines to see and organize information the way humans do, but at massive speed and scale.</Text>
			<Text>
				Exovera automatically pulls in vast stores of publicly available print, broadcast, and social media data and uses expert analysts to
				collect sampled training data (sets of documents containing identified narratives) used for machine learning AI. Training data is
				used to build a set of proprietary AI models and algorithms to automatically classify incoming media streams, by narrative, in near
				real-time. The Exovera platform then connects this machine-categorized content with other associated data, including images, videos,
				and social media reactions. Aggregate data modeling enables development of additional classes and analytic domains based onpublicly
				available associated metadata.
			</Text>
			<Text underline subHeader>
				Note on AI Accuracy
			</Text>
			<Text>
				This demonstration of machine learning is an ongoing project, and Exovera continues to expand the training data set. AI model
				accuracy improves over time, often in fits and starts, as training data sets grow. Exovera aims to--and typically will--achieve
				narrative model accuracy rates in excess of 90 percent, but model accuracy will vary from topic to topic and narrative to narrative
				due to multiple factors, such as topical or conceptual complexity. Rigorous QA/QC practices and an ongoing analyst feedback loop are
				implemented to ensure consistent and ever moreaccurate results. Lower accuracy rates, however, will still yield valid directional
				results, and remain useful to reveal and verify patterns and trends. Even at high rates of accuracy some misclassification of
				specific items are to be expected.
			</Text>

			<Accuracy>
				<AccuracyHeader>Latest Accuracy Rates by Narrative* (Target: 85%):</AccuracyHeader>
				<Topic>
					<TopicName>{topics.left.name}</TopicName>
					<NarrativeList>
						{topics.left.support.narratives.map((nar) => {
							return (
								<Narrative key={nar.id}>
									{nar.name}
									<Score>{_.isNumber(nar.acc) ? `${nar.acc}%` : "N/A"}</Score>
								</Narrative>
							);
						})}
						{topics.left.oppose.narratives.map((nar) => {
							return (
								<Narrative key={nar.id}>
									{nar.name}
									<Score>{_.isNumber(nar.acc) ? `${nar.acc}%` : "N/A"}</Score>
								</Narrative>
							);
						})}
					</NarrativeList>
				</Topic>
				<Topic>
					<TopicName>{topics.right.name}</TopicName>
					<NarrativeList>
						{topics.right.support.narratives.map((nar) => {
							return (
								<Narrative key={nar.id}>
									{nar.name}
									<Score>{_.isNumber(nar.acc) ? `${nar.acc}%` : "N/A"}</Score>
								</Narrative>
							);
						})}
						{topics.right.oppose.narratives.map((nar) => {
							return (
								<Narrative key={nar.id}>
									{nar.name}
									<Score>{_.isNumber(nar.acc) ? `${nar.acc}%` : "N/A"}</Score>
								</Narrative>
							);
						})}
					</NarrativeList>
				</Topic>
				<Text>
					* AI accuracy for a particular narrative is the precision of our AI classifier for that narrative. In other words, it can be
					defined as true positives divided by the sum of true positives and false positives. It is calculated as the number of articles
					confirmed by the Exovera analysts as containing the narrative out of the total number of articles that the AI classifier has
					predicted a score above 60 for that narrative.
				</Text>
			</Accuracy>

			{/* <SectionTitle>III. Contacts</SectionTitle>

			<Text>CONTACT</Text> */}
			<Text italic>
				<Bold>About Exovera:</Bold> Exovera LLC, a subsidiary of SOS International LLC (SOSi), is a non-partisan global provider of both
				traditional media monitoring and analyst-in-the-loop artificial intelligence-based media Narrative analytics services. Our
				government and commercial customers work in all regions of the world where, for more than 14 years, Exovera has provided local
				situational awareness, media narratives assessment and traditional and social media analytics across a wide range of complex
				security, political, economic, social, and humanitarian issues.
			</Text>
		</Root>
	);
};

// Methodology.defaultProps = {};

// Methodology.propTypes /* remove-proptypes */  = {};

export default Methodology;

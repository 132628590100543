// import fetch from "cross-fetch";
import {
	CLICK_FILTER_TOGGLE,
	CLICK_TOP_TOGGLE,
	DATE_SELECT,
	REQUESTING_DATA,
	RECEIVE_DATA,
	SET_MIDDLE_DATA,
	SET_BOTTOM_DATA,
} from "actions/constants.js";

import _ from "underscore";
// const data = staticData;
import data from "static/data.json";

// const data = process.env.NODE_ENV === "production" ? require("./src/static/data.json") : require("static/data.json");
// const staticData
// import staticData from "staticData";
// console.log("visibleConst1", visibleConst1);
// console.log("setData", setData);
// import mockData from "static/dataOld.json";

// // -------- DATA MOCKING --------
// import mocker from "mocker-data-generator";
// import mockData from "lib/mockData.js";
// // import mockState from "lib/mockState.js"; // Static version of the mocked data (huge file)
// import {
// 	atLeastOneOfEach,
// 	randomItem,
// 	randomItems,
// 	randomTags,
// 	narIdArray,
// 	topicsAndNarIdArray,
// 	datesIdArray,
// 	biasIdArray,
// 	fakeSourceArray,
// 	timeseriesArray,
// 	stateNamesArray,
// } from "lib/mockHelpers.js";

// const article = {
// 	fdate: { static: 1574035200000 },
// 	// narratives: {
// 	// 	function() {
// 	// 		return randomItems(narIdArray);
// 	// 	},
// 	// },
// 	tags: {
// 		function() {
// 			return atLeastOneOfEach([topicsAndNarIdArray, datesIdArray, biasIdArray]);
// 		},
// 	},
// 	shares: { faker: "random.number()" },
// 	source: {
// 		function() {
// 			return randomItem(fakeSourceArray);
// 		},
// 	},
// 	title: { faker: "random.words()" },
// 	hrefUrl: {
// 		static:
// 			"https://www.greensburgdailynews.com/news/local_news/covid-19-relief-for-indiana-farmers/article_9e44077a-a9bf-11ea-914a-477e25e3b18e.html",
// 	},
// };

// const phrases = {
// 	value: { faker: "random.number()" },
// 	text: { faker: "random.words()" },
// };

// const facet = {
// 	total: { faker: "random.number()" },
// 	rate7: {
// 		function() {
// 			return _.random(-100, 100) / 100;
// 		},
// 	},
// 	rate30: {
// 		function() {
// 			return _.random(-100, 100) / 100;
// 		},
// 	},
// 	// timeseries: {
// 	// 	function() {
// 	// 		return randomItem(timeseriesArray);
// 	// 	},
// 	// },
// 	// topState: {
// 	// 	function() {
// 	// 		return randomItem(stateNamesArray);
// 	// 	},
// 	// },
// 	// avgReaction: { faker: "random.number()" },
// 	// articles: { hasMany: "article", amount: 10 },
// 	// phrases: { hasMany: "phrases", amount: 10 },
// };

// const timeseries = {
// 	"1538092800000": { hasOne: "timeseriesFacetGroup" },
// 	"1538179200000": { hasOne: "timeseriesFacetGroup" },
// 	"1538265600000": { hasOne: "timeseriesFacetGroup" },
// 	"1538352000000": { hasOne: "timeseriesFacetGroup" },
// 	"1538438400000": { hasOne: "timeseriesFacetGroup" },
// 	"1538524800000": { hasOne: "timeseriesFacetGroup" },
// 	"1538611200000": { hasOne: "timeseriesFacetGroup" },
// 	"1538697600000": { hasOne: "timeseriesFacetGroup" },
// 	"1538784000000": { hasOne: "timeseriesFacetGroup" },
// 	"1538870400000": { hasOne: "timeseriesFacetGroup" },
// 	"1538956800000": { hasOne: "timeseriesFacetGroup" },
// 	"1539043200000": { hasOne: "timeseriesFacetGroup" },
// 	"1539129600000": { hasOne: "timeseriesFacetGroup" },
// 	"1539216000000": { hasOne: "timeseriesFacetGroup" },
// 	"1539302400000": { hasOne: "timeseriesFacetGroup" },
// 	"1539388800000": { hasOne: "timeseriesFacetGroup" },
// 	"1539475200000": { hasOne: "timeseriesFacetGroup" },
// 	"1539561600000": { hasOne: "timeseriesFacetGroup" },
// 	"1539648000000": { hasOne: "timeseriesFacetGroup" },
// 	"1539734400000": { hasOne: "timeseriesFacetGroup" },
// 	"1539820800000": { hasOne: "timeseriesFacetGroup" },
// 	"1539907200000": { hasOne: "timeseriesFacetGroup" },
// 	"1539993600000": { hasOne: "timeseriesFacetGroup" },
// 	"1540080000000": { hasOne: "timeseriesFacetGroup" },
// 	"1540166400000": { hasOne: "timeseriesFacetGroup" },
// 	"1540252800000": { hasOne: "timeseriesFacetGroup" },
// 	"1540339200000": { hasOne: "timeseriesFacetGroup" },
// 	"1540425600000": { hasOne: "timeseriesFacetGroup" },
// 	"1540512000000": { hasOne: "timeseriesFacetGroup" },
// 	"1540598400000": { hasOne: "timeseriesFacetGroup" },
// 	"1540684800000": { hasOne: "timeseriesFacetGroup" },
// 	"1540771200000": { hasOne: "timeseriesFacetGroup" },
// 	"1540857600000": { hasOne: "timeseriesFacetGroup" },
// 	"1540944000000": { hasOne: "timeseriesFacetGroup" },
// 	"1541030400000": { hasOne: "timeseriesFacetGroup" },
// 	"1541116800000": { hasOne: "timeseriesFacetGroup" },
// 	"1541203200000": { hasOne: "timeseriesFacetGroup" },
// 	"1541289600000": { hasOne: "timeseriesFacetGroup" },
// 	"1541376000000": { hasOne: "timeseriesFacetGroup" },
// 	"1541462400000": { hasOne: "timeseriesFacetGroup" },
// 	"1541548800000": { hasOne: "timeseriesFacetGroup" },
// 	"1541635200000": { hasOne: "timeseriesFacetGroup" },
// 	"1541721600000": { hasOne: "timeseriesFacetGroup" },
// 	"1541808000000": { hasOne: "timeseriesFacetGroup" },
// 	"1541894400000": { hasOne: "timeseriesFacetGroup" },
// 	"1541980800000": { hasOne: "timeseriesFacetGroup" },
// 	"1542067200000": { hasOne: "timeseriesFacetGroup" },
// 	"1542153600000": { hasOne: "timeseriesFacetGroup" },
// 	"1542240000000": { hasOne: "timeseriesFacetGroup" },
// 	"1542326400000": { hasOne: "timeseriesFacetGroup" },
// 	"1542412800000": { hasOne: "timeseriesFacetGroup" },
// };

// const timeseriesFacetGroup = {
// 	t: { hasOne: "timeseriesFacet" },
// 	b: { hasOne: "timeseriesFacet" },
// 	tsa: { hasOne: "timeseriesFacet" },
// 	toa: { hasOne: "timeseriesFacet" },
// 	bsa: { hasOne: "timeseriesFacet" },
// 	boa: { hasOne: "timeseriesFacet" },
// 	ts1: { hasOne: "timeseriesFacet" },
// 	ts2: { hasOne: "timeseriesFacet" },
// 	ts3: { hasOne: "timeseriesFacet" },
// 	ts4: { hasOne: "timeseriesFacet" },
// 	to1: { hasOne: "timeseriesFacet" },
// 	to2: { hasOne: "timeseriesFacet" },
// 	to3: { hasOne: "timeseriesFacet" },
// 	to4: { hasOne: "timeseriesFacet" },
// 	bs1: { hasOne: "timeseriesFacet" },
// 	bs2: { hasOne: "timeseriesFacet" },
// 	bs3: { hasOne: "timeseriesFacet" },
// 	bs4: { hasOne: "timeseriesFacet" },
// 	bo1: { hasOne: "timeseriesFacet" },
// 	bo2: { hasOne: "timeseriesFacet" },
// 	bo3: { hasOne: "timeseriesFacet" },
// 	bo4: { hasOne: "timeseriesFacet" },
// };

// const timeseriesFacet = {
// 	a: {
// 		function() {
// 			return _.random(0, 1000);
// 		},
// 	},
// 	l: {
// 		function() {
// 			return _.random(0, 400);
// 		},
// 	},
// 	r: {
// 		function() {
// 			return _.random(0, 700);
// 		},
// 	},
// };

// const facetGroup = {
// 	total: { faker: "random.number()" },
// 	t: { hasOne: "facet" },
// 	b: { hasOne: "facet" },
// 	tsa: { hasOne: "facet" },
// 	toa: { hasOne: "facet" },
// 	bsa: { hasOne: "facet" },
// 	boa: { hasOne: "facet" },
// 	ts1: { hasOne: "facet" },
// 	ts2: { hasOne: "facet" },
// 	ts3: { hasOne: "facet" },
// 	ts4: { hasOne: "facet" },
// 	to1: { hasOne: "facet" },
// 	to2: { hasOne: "facet" },
// 	to3: { hasOne: "facet" },
// 	to4: { hasOne: "facet" },
// 	bs1: { hasOne: "facet" },
// 	bs2: { hasOne: "facet" },
// 	bs3: { hasOne: "facet" },
// 	bs4: { hasOne: "facet" },
// 	bo1: { hasOne: "facet" },
// 	bo2: { hasOne: "facet" },
// 	bo3: { hasOne: "facet" },
// 	bo4: { hasOne: "facet" },
// };

// const biasGroup = {
// 	a: { hasOne: "facetGroup" },
// 	l: { hasOne: "facetGroup" },
// 	r: { hasOne: "facetGroup" },
// };

// load data
export const fetchData = () => {
	return (dispatch) => {
		dispatch({ type: REQUESTING_DATA });
		// console.log("mockData", mockData);
		// console.log("data", data);
		dispatch({ type: RECEIVE_DATA, response: JSON.parse(JSON.stringify(data)) });

		// setTimeout(() => {
		// 	mocker()
		// 		.schema("timeseriesFacet", timeseriesFacet, 800)
		// 		.schema("timeseriesFacetGroup", timeseriesFacetGroup, 60)
		// 		.schema("timeseries", timeseries, 1)
		// 		.schema("article", article, 600)
		// 		.schema("phrases", phrases, 50)
		// 		.schema("facet", facet, 50)
		// 		.schema("facetGroup", facetGroup, 10)
		// 		.schema("biasGroup", biasGroup, 6)
		// 		.schema("7days", biasGroup, 1)
		// 		.schema("30days", biasGroup, 1)
		// 		.schema("history", biasGroup, 1)
		// 		.build()
		// 		.then(
		// 			(data) => {
		// 				console.log("mocked data, bro", data);
		// 				const mockery = {
		// 					// "7days": data["7days"][0],
		// 					// "30days": data["30days"][0],
		// 					// history: data.history[0],
		// 					articles: data.article,
		// 					timeseries: data.timeseries[0],
		// 					stats: {
		// 						a7days: data["7days"][0].a,
		// 						a30days: data["30days"][0].a,
		// 						ahistory: data.history[0].a,
		// 						l7days: data["7days"][0].l,
		// 						l30days: data["30days"][0].l,
		// 						lhistory: data.history[0].l,
		// 						r7days: data["7days"][0].r,
		// 						r30days: data["30days"][0].r,
		// 						rhistory: data.history[0].r,
		// 					},
		// 				};
		// 				dispatch({ type: RECEIVE_DATA, response: { data: mockery, setup: mockData.setup } });
		// 			},
		// 			(err) => console.error(err)
		// 		);
		// }, 500);
	};
};
// ---------- END MOCKING -----------

export const clickTopToggle = (toggle) => {
	return (dispatch) => {
		dispatch({ type: CLICK_TOP_TOGGLE, toggle });
	};
};

export const clickFilterToggle = (filter) => {
	return (dispatch) => {
		dispatch({ type: CLICK_FILTER_TOGGLE, filter });
	};
};

export const dateSelect = (dateObj) => {
	return (dispatch) => {
		dispatch({ type: DATE_SELECT, dateObj });
	};
};

export const setMiddleData = (option) => {
	return (dispatch) => {
		dispatch({ type: SET_MIDDLE_DATA, option });
	};
};

export const clickBottomTab = (tab) => {
	return (dispatch) => {
		dispatch({ type: SET_BOTTOM_DATA, tab });
	};
};

// colorblindLib
// nestTopics
// hydrateTopics
// flatNarrativesOnly
// formatPie
// formatGraph
// formatTable
// formatDataOptions
// selectTabs
// filterArticles

import _ from "underscore";
import vars from "@exovera/narrative-tracker-variables";

export const colorblindLib = [
	false,
	"colorblindDashed",
	"colorblindTinyDots",
	"colorblindDots",
	"colorblindBroken",
	"colorblindStripes",
	"colorblindBig",
	"colorblindSmall",
	"colorblindTiny",
];

export const nestTopics = (topics) => {
	const nestedTopics = {};
	nestedTopics.left = _.find(topics, (topic) => {
		return topic.placement === "left";
	});
	nestedTopics.right = _.find(topics, (topic) => {
		return topic.placement === "right";
	});
	nestedTopics.left.oppose = _.find(topics, (topic) => {
		return topic.placement === "leftOppose";
	});
	nestedTopics.left.support = _.find(topics, (topic) => {
		return topic.placement === "leftSupport";
	});
	nestedTopics.right.oppose = _.find(topics, (topic) => {
		return topic.placement === "rightOppose";
	});
	nestedTopics.right.support = _.find(topics, (topic) => {
		return topic.placement === "rightSupport";
	});
	nestedTopics.left.oppose.narratives = _.filter(topics, (topic) => {
		return topic.placement === "leftOpposeNarrative";
	});
	nestedTopics.left.support.narratives = _.filter(topics, (topic) => {
		return topic.placement === "leftSupportNarrative";
	});
	nestedTopics.right.oppose.narratives = _.filter(topics, (topic) => {
		return topic.placement === "rightOpposeNarrative";
	});
	nestedTopics.right.support.narratives = _.filter(topics, (topic) => {
		return topic.placement === "rightSupportNarrative";
	});

	return nestedTopics;
};

// adds narratives fields to the flat topics objects
export const hydrateTopics = (params) => {
	const { topics, flatTopics } = params;
	const hydratedFlatTopics = JSON.parse(JSON.stringify(flatTopics));

	const leftId = topics.left.id;
	const rightId = topics.right.id;
	const leftSupportId = topics.left.support.id;
	const leftOpposeId = topics.left.oppose.id;
	const rightSupportId = topics.right.support.id;
	const rightOpposeId = topics.right.oppose.id;
	hydratedFlatTopics[leftId].support = topics.left.support;
	hydratedFlatTopics[leftId].oppose = topics.left.oppose;
	hydratedFlatTopics[rightId].support = topics.right.support;
	hydratedFlatTopics[rightId].oppose = topics.right.oppose;
	hydratedFlatTopics[leftSupportId].narratives = topics.left.support.narratives;
	hydratedFlatTopics[leftOpposeId].narratives = topics.left.oppose.narratives;
	hydratedFlatTopics[rightSupportId].narratives = topics.right.support.narratives;
	hydratedFlatTopics[rightOpposeId].narratives = topics.right.oppose.narratives;

	return hydratedFlatTopics;
};

export const flatNarrativesOnly = (topicsOG) => {
	const narOnly = {};
	const topics = JSON.parse(JSON.stringify(topicsOG));
	const flatNarrativesArray = [
		...topics.left.oppose.narratives,
		...topics.left.support.narratives,
		...topics.right.oppose.narratives,
		...topics.right.support.narratives,
	];

	_.each(flatNarrativesArray, (nar) => {
		narOnly[nar.id] = nar;
	});

	return narOnly;
};

export const formatPie = (params) => {
	const { subject, data, topics } = params;
	// console.log("formatPie", params);
	const topLevelPie = !subject || !subject.id; // use overall if there's no specific subject
	const secondLevelPie = !!subject.support;

	// pick the slice
	let slices = [];
	let title = "";
	let subTitle = "";
	let altTitle = "";
	let shade = "";
	let donut = true;
	let shortLegend = false;
	let opposePattern = true;
	let total = 0;

	if (topLevelPie) {
		const leftSlice = JSON.parse(JSON.stringify(topics.left));
		const rightSlice = JSON.parse(JSON.stringify(topics.right));

		leftSlice.name = `${leftSlice.name} Narratives`;
		leftSlice.value = data[leftSlice.id].total;
		rightSlice.name = `${rightSlice.name} Narratives`;
		rightSlice.value = data[rightSlice.id].total;

		slices = [leftSlice, rightSlice];

		// FLAG_DYNAMIC_TITLES_NEEDED
		title = "Relative Media Share";
		subTitle = "(% by Volume)";
		altTitle = "% by Candidate";
		donut = false;
		shortLegend = true;
		_.each(slices, (obj) => {
			total += obj.value;
		});
	} else if (secondLevelPie) {
		// support/oppose chart
		const support = JSON.parse(JSON.stringify(subject.support));
		const oppose = JSON.parse(JSON.stringify(subject.oppose));

		support.value = data[support.id].total;
		oppose.value = data[oppose.id].total;

		slices = [support, oppose];

		title = subject.name;
		subTitle = "(% Supporting/Opposing)";
		altTitle = `% of ${subject.name}`;
		shade = subject.shade;
		donut = true;
		shortLegend = !!subject.shortLegend;
		_.each(slices, (obj) => {
			total += obj.value;
		});
	} else {
		// Install narratives here
		_.each(subject.narratives, (nar) => {
			const slice = JSON.parse(JSON.stringify(nar));
			slice.value = data[slice.id].total;

			slices.push(slice);
		});

		slices = _.sortBy(slices, "value").reverse();

		shade = subject.shade;
		title = subject.name;
		altTitle = `% That ${subject.name}`;
		donut = false;
		opposePattern = false;
		shortLegend = !!subject.shortLegend;
		_.each(slices, (obj) => {
			total += obj.value;
		});
	}

	return {
		altTitle,
		donut,
		opposePattern,
		shade,
		shortLegend,
		slices,
		subTitle,
		title,
		total,
	};
};

export const formatGraph = (params) => {
	const { subject, masterTimeseries, currentDateObj, biasId, topics } = params;
	// console.log("formatGraph", params);
	const overallSubject = !subject || !subject.id; // use overall if there's no specific subject
	const secondLevelSubject = !!subject.support;

	const keys = takeKeys({ timeseries: masterTimeseries, dateObj: currentDateObj });

	// pick the slice
	let lines = [];
	let title = "";

	if (overallSubject) {
		const leftId = topics.left.id;
		const rightId = topics.right.id;

		lines = [
			{
				id: leftId,
				name: `${topics.left.name} Narratives`,
				color: {
					dark: topics.left.color,
					light: topics.left.color,
					colorblindstripes: colorblindLib[0],
				},
				rangeEnd: Number(keys[keys.length - 1]),
				rangeStart: Number(keys[0]),
				timeseries: buildTimeseries({ masterTimeseries, timeseriesKeys: keys, topicId: leftId, biasId }),
			},
			{
				id: rightId,
				name: `${topics.right.name} Narratives`,
				color: {
					dark: topics.right.color,
					light: topics.right.color,
					colorblindstripes: colorblindLib[1],
				},
				rangeEnd: Number(keys[keys.length - 1]),
				rangeStart: Number(keys[0]),
				timeseries: buildTimeseries({ masterTimeseries, timeseriesKeys: keys, topicId: rightId, biasId }),
			},
		];

		// FLAG_DYNAMIC_TITLES_NEEDED
		title = "Volume by Candidate";
	} else if (secondLevelSubject) {
		// support/oppose chart
		const supportId = subject.support.id;
		const opposeId = subject.oppose.id;

		lines = [
			{
				id: supportId,
				name: subject.support.name,
				color: {
					dark: subject.support.color,
					light: subject.support.color,
					colorblindstripes: colorblindLib[0],
				},
				rangeEnd: Number(keys[keys.length - 1]),
				rangeStart: Number(keys[0]),
				timeseries: buildTimeseries({ masterTimeseries, timeseriesKeys: keys, topicId: supportId, biasId }),
			},
			{
				id: opposeId,
				name: subject.oppose.name,
				color: {
					dark: subject.oppose.color,
					light: subject.oppose.color,
					colorblindstripes: colorblindLib[1],
				},
				rangeEnd: Number(keys[keys.length - 1]),
				rangeStart: Number(keys[0]),
				timeseries: buildTimeseries({ masterTimeseries, timeseriesKeys: keys, topicId: opposeId, biasId }),
			},
		];

		title = `Volume of ${subject.name}`;
	} else {
		// Install narratives here
		_.each(subject.narratives, (nar, index) => {
			lines.push({
				id: nar.id,
				name: nar.name,
				color: {
					dark: nar.color,
					light: nar.color,
					colorblindstripes: colorblindLib[index],
				},
				rangeEnd: Number(keys[keys.length - 1]),
				rangeStart: Number(keys[0]),
				timeseries: buildTimeseries({ masterTimeseries, timeseriesKeys: keys, topicId: nar.id, biasId }),
			});
		});

		title = `Volume of Narratives That ${subject.name}`;
	}

	return {
		lines,
		title,
	};
};

export const formatTable = (params) => {
	const { subject, data, historicData, topics } = params;
	// console.log("formatTable", params);
	const overallSubject = !subject || !subject.id; // use overall if there's no specific subject
	const secondLevelSubject = !!subject.support;

	let rows = [];
	let subjectType = "";

	if (overallSubject) {
		const leftId = topics.left.id;
		const rightId = topics.right.id;
		const leftData = JSON.parse(JSON.stringify(data[leftId]));
		const rightData = JSON.parse(JSON.stringify(data[rightId]));
		const historicLeftData = historicData[leftId];
		const historicRightData = historicData[rightId];

		leftData.id = leftId;
		leftData.name = `${topics.left.name} Narratives`;
		leftData.color = topics.left.color;
		leftData.colorblindstripes = colorblindLib[0];
		leftData.rate7 = historicLeftData.rate7;
		leftData.rate30 = historicLeftData.rate30;

		rightData.id = rightId;
		rightData.name = `${topics.right.name} Narratives`;
		rightData.color = topics.right.color;
		rightData.colorblindstripes = colorblindLib[1];
		rightData.rate7 = historicRightData.rate7;
		rightData.rate30 = historicRightData.rate30;

		rows = [leftData, rightData];
		subjectType = "Narratives";
	} else if (secondLevelSubject) {
		// support/oppose chart
		const supportId = subject.support.id;
		const opposeId = subject.oppose.id;
		const supportData = JSON.parse(JSON.stringify(data[supportId]));
		const opposeData = JSON.parse(JSON.stringify(data[opposeId]));
		const historicSupportData = historicData[supportId];
		const historicOpposeData = historicData[opposeId];

		supportData.id = supportId;
		supportData.name = `Narratives That ${subject.support.name}`;
		supportData.color = subject.support.color;
		supportData.colorblindstripes = colorblindLib[0];
		supportData.rate7 = historicSupportData.rate7;
		supportData.rate30 = historicSupportData.rate30;

		opposeData.id = opposeId;
		opposeData.name = `Narratives That ${subject.oppose.name}`;
		opposeData.color = subject.oppose.color;
		opposeData.colorblindstripes = colorblindLib[1];
		opposeData.rate7 = historicOpposeData.rate7;
		opposeData.rate30 = historicOpposeData.rate30;

		rows = [supportData, opposeData];
		subjectType = subject.name;
	} else {
		// Install narratives here
		_.each(subject.narratives, (nar, index) => {
			const narData = data[nar.id];
			const historicNarData = historicData[nar.id];

			narData.id = nar.id;
			narData.name = nar.name;
			narData.color = nar.color;
			narData.colorblindstripes = colorblindLib[index];
			narData.rate7 = historicNarData.rate7;
			narData.rate30 = historicNarData.rate30;

			rows.push(narData);
		});

		subjectType = `Narratives That ${subject.name}`;
	}

	return {
		rows,
		subjectType,
	};
};

// Returns a new array of tabs. Does not consider the previous set of tabs, or their state
export const selectTabs = (params) => {
	const { subject, data, topics } = params;
	// console.log("selectTabs", params);
	const overallSubject = !subject || !subject.id; // use overall if there's no specific subject
	const secondLevelSubject = !!subject.support;

	let tabs = [];

	if (overallSubject) {
		tabs = [
			{
				id: topics.left.id,
				name: `${topics.left.name} Narratives`,
				color: topics.left.color,
				active: true,
			},
			{
				id: topics.right.id,
				name: `${topics.right.name} Narratives`,
				color: topics.right.color,
			},
		];
	} else if (secondLevelSubject) {
		// support/oppose

		tabs = [
			{
				id: subject.support.id,
				name: subject.support.name,
				color: subject.support.color,
				active: true,
			},
			{
				id: subject.oppose.id,
				name: subject.oppose.name,
				color: subject.oppose.color,
			},
		];
	} else {
		_.each(subject.narratives, (nar, index) => {
			tabs.push({
				id: nar.id,
				name: nar.name,
				color: nar.color,
				active: index === 0,
			});
		});
	}

	return tabs;
};

export const formatDataOptions = (params) => {
	const { topics, title } = params;

	const left = JSON.parse(JSON.stringify(topics.left));
	const right = JSON.parse(JSON.stringify(topics.right));

	left.name = `All ${left.name} Narratives`;
	right.name = `All ${right.name} Narratives`;
	left.indent = 20;
	right.indent = 20;

	const leftSupport = left.support;
	const leftOppose = left.oppose;
	leftSupport.indent = 40;
	leftOppose.indent = 40;

	const rightSupport = right.support;
	const rightOppose = right.oppose;
	rightSupport.indent = 40;
	rightOppose.indent = 40;

	const options = [
		{
			id: false,
			name: title,
			active: true,
			color: vars.sky_1,
		},
		left,
		leftSupport,
		leftOppose,
		right,
		rightSupport,
		rightOppose,
	];

	return options;
};

export const filterAnnotations = (params) => {
	const { annotations, biasId } = params;

	const filteredByBias =
		biasId === "a" // API doesn't pass "a", just "l" & "r"
			? annotations
			: _.filter(annotations, (annotation) => {
					return _.contains(annotation.narratives, biasId);
			  });

	return filteredByBias;
};

export const filterArticles = (params) => {
	const { articles, tabId, dateObj, biasId } = params;
	// console.log("filterArticles", params);

	const filteredByFacet = _.filter(articles, (art) => {
		return _.contains(art.tags, tabId);
	});

	const filteredByDate = _.filter(filteredByFacet, (art) => {
		// no end: ate always ends today (or as close as possible)
		// Subtracting 12 hours to prevent (some) potential timezone offset issues
		return art.fdate >= dateObj.startDate - 12 * 60 * 60 * 1000;
	});

	const filteredByBias =
		biasId === "a" // API doesn't pass "a", just "l" & "r"
			? filteredByDate
			: _.filter(filteredByDate, (art) => {
					return _.contains(art.tags, biasId);
			  });

	const sortedByInteractions = _.sortBy(filteredByBias, "shares").reverse();

	return _.first(sortedByInteractions, 10);
};

export const takeKeys = (params) => {
	const { timeseries, dateObj } = params;
	if (_.isEmpty(dateObj)) {
		return timeseries;
	} else {
		const keys = [];
		const dateBegin = Number(dateObj.startDate);
		const dateEnd = Number(dateObj.endDate);
		_.each(timeseries, (value, key) => {
			if (key >= dateBegin && key <= dateEnd) {
				keys.push(key);
			}
		});

		return keys;
	}
};

// timeseriesKeys will be the length of the timeseries that is returned - ie, update the dates and time period based on this
export const buildTimeseries = (params) => {
	const { masterTimeseries, timeseriesKeys, topicId, biasId } = params;
	const timeseries = {};

	_.each(timeseriesKeys, (key) => {
		timeseries[key] = masterTimeseries[key][topicId][biasId];
	});

	return timeseries;
};

import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const Svg = styled.svg`
	height: 100%;
	width: 100%;
	transform: ${(props) => `scale(${props.scale})`};
`;

const LogoIcon = (props) => {
	const { scale } = props;
	return (
		<Svg version="1.1" x="0px" y="0px" viewBox="0 0 479.3822 480" scale={scale}>
			<polygon points="0 480 239.382 480 239.382 240 0 480" fill="#2e6fbd" />
			<polygon points="0 0 239.382 240 239.382 0 0 0" fill="#8cc94e" />
			<polygon
				points="239.383 0 239.382 0 239.382 240 479.382 240 239.501 0 239.383 0"
				fill="#bcde87"
			/>
			<polygon
				points="239.382 480 239.383 480 239.501 480 479.382 240 239.382 240 239.382 480"
				fill="#123984"
			/>
		</Svg>
	);
};

LogoIcon.defaultProps = {
	scale: 1,
};

LogoIcon.propTypes /* remove-proptypes */  = {
	scale: PropTypes.number,
};

export default LogoIcon;

// import _ from "underscore";

// detectBrowser
// getScrollbarSize
// log
// shadeColor

export const detectBrowser = () => {
	// Opera 8.0+
	const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;

	// Firefox 1.0+
	const isFirefox = typeof InstallTrigger !== "undefined";

	// Safari 3.0+ "[object HTMLElementConstructor]"
	const isSafari =
		/constructor/i.test(window.HTMLElement) ||
		(function(p) {
			return p.toString() === "[object SafariRemoteNotification]";
		})(!window["safari"] || (typeof safari !== "undefined" && safari.pushNotification));

	// Internet Explorer 6-11
	const isIE = /* @cc_on!@ */ false || !!document.documentMode;

	// Edge 20+
	const isEdge = !isIE && !!window.StyleMedia;

	// Chrome 1+
	const isChrome = !!window.chrome && !!window.chrome.webstore;

	// Blink engine detection
	const isBlink = (isChrome || isOpera) && !!window.CSS;
	// return "ie";
	return isBlink
		? "blink"
		: isChrome
			? "chrome"
			: isEdge
				? "edge"
				: isIE
					? "ie"
					: isSafari
						? "safari"
						: isFirefox
							? "firefox"
							: isOpera
								? "opera"
								: undefined;
};

export const getScrollbarSize = () => {
	const doc = document.documentElement;
	const dummyScroller = document.createElement("div");
	dummyScroller.setAttribute("style", "width:99px;height:99px;" + "position:absolute;top:-9999px;overflow:scroll;");
	doc.appendChild(dummyScroller);
	const scrollbarSize = dummyScroller.offsetWidth - dummyScroller.clientWidth;
	doc.removeChild(dummyScroller);
	return scrollbarSize;
};

export const log = (text) => {
	if (process.env.NODE_ENV !== "production") {
		console.log("+", ...text);
	}
};

// mostly from https://stackoverflow.com/a/13532993/2393562
// to lighten 10%: shadeColor({ color: "#00bff3", percent: 10 })
// to darken 10%: shadeColor({ color: "#00bff3", percent: -10 })
export const shadeColor = (params) => {
	const { color, percent } = params;
	const colorHex = color.length === 6 ? `#${color}` : color;

	let R = parseInt(colorHex.substring(1, 3), 16);
	let G = parseInt(colorHex.substring(3, 5), 16);
	let B = parseInt(colorHex.substring(5, 7), 16);

	R = parseInt(R * (100 + percent) / 100, 10);
	G = parseInt(G * (100 + percent) / 100, 10);
	B = parseInt(B * (100 + percent) / 100, 10);

	R = R < 255 ? R : 255;
	G = G < 255 ? G : 255;
	B = B < 255 ? B : 255;

	const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
	const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
	const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

	return `#${RR}${GG}${BB}`;
};

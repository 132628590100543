/* eslint-disable react/jsx-filename-extension */
// addSwatch
// formatNumber

import React, { Fragment } from "react";
// import _ from "underscore";

import styled from "styled-components";
import vars, { focusStyle } from "@exovera/narrative-tracker-variables";

import { Arrow, Caret } from "@exovera/icons-lite";
import { clickedEnter, formatPosition, getDateObjectFromUTC, higherContrast } from "@exovera/helpers";
import dateLib from "lib/dateLib.js";

const SwatchBox = styled.span`
	display: block;
	position: relative;
	padding-left: 30px;
	line-height: 130%;
	opacity: ${(props) => (props.fade ? 0.3 : 1)};
	@media all and (max-width: 500px) {
		padding-left: 22px;
	}
`;
const ActiveDiamond = styled.span`
	display: block;
	position: absolute;
	height: 6px;
	width: 6px;
	left: -32px;
	top: 5px;
	background-color: ${vars.sky_2};
	transform: rotate(45deg);
`;
const Swatch = styled.span`
	display: block;
	position: absolute;
	height: 12px;
	width: 12px;
	left: 0;
	top: 2px;
	border-radius: 50%;
	background-color: ${(props) => props.bgColor};
`;
const InsetShadow = styled.div`
	border: 1px solid ${(props) => (props.shadow ? vars.seal_3 : "transparent")};
	/* box-shadow: ${(props) => (props.shadow ? `0 0 5px -2px ${vars.sky_2} inset` : "none")}; */
`;
const HighlightSpan = styled.span`
	${(props) => (props.underlined ? vars.mainfontBold : vars.mainfont)};
	border-bottom: solid 2px ${(props) => props.underlined || "transparent"};
`;

const PaddingLeft = styled.span`
	display: inline-block;
	padding-left: 30px;
	@media all and (max-width: 500px) {
		padding-left: 22px;
	}
`;

const Date = styled.span`
	display: block;
	position: absolute;
	height: 26px;
	width: 60px;
	top: 50%;
	left: -7px;
	margin-top: -13px;
	padding: 6px 0;
	border-radius: 4px;
	font-size: 13px;
	${vars.mainfontBold};
	color: ${(props) => props.textColor};
	background-color: ${(props) => props.backgroundColor};
	text-align: center;
`;
const Source = styled.span`
	${vars.mainfontBold};
	color: ${vars.sky_2};
	word-break: break-word;
	line-height: 120%;
`;

const TitleSpan = styled.span`
	margin-right: 10px;
	line-height: 130%;
	color: ${vars.sky_1};
`;
const TagBox = styled.span`
	display: inline-block;
	padding-right: 2px;
	text-align: left;
`;
const Tag = styled.span`
	display: inline-block;
	position: relative;
	height: 10px;
	width: ${(props) => (props.colorBlindMode ? 40 : 10)}px;
	margin: 0 4px;
	border-radius: 2px;
	/* border: 1px solid ${vars.grey_2}; */
	background-color: ${(props) => props.bgColor};
	z-index: 1;
	transition: width 350ms ease-out 50ms;
	border: 1px solid transparent;
	&:focus {
		border: 1px solid ${(props) => (props.showFocus ? vars.cerulean_1 : "transparent")};
		outline: none;
		box-shadow: ${(props) => (props.showFocus ? `0 0 15px 0 ${vars.cerulean_1}` : "none")};
	}
`;
const Shares = styled.span`
	display: block; /* FLAG_SHOW_INTERACTIONS_AGAIN  */
	/* display: none; */
	text-align: center;
	${vars.numberfont};
`;
const Numbers = styled.span`
	display: block;
	${vars.numberfont};
	opacity: ${(props) => (props.fade ? 0.3 : 1)};
`;
const UpArrow = styled.span`
	display: inline-block;
	position: relative;
	top: 2px;
	margin: 0 10px 0 -4px;
	height: 10px;
	width: 20px;
	transform: rotate(90deg);
`;
const DownArrow = styled.span`
	display: inline-block;
	position: relative;
	top: 2px;
	margin: 0 10px 0 -6px;
	height: 10px;
	width: 20px;
	transform: rotate(-90deg);
`;
const MaybeIsolate = styled.span`
	opacity: ${(props) => (props.fade ? 0.3 : 1)};
`;

export const tagTransform = (params) => {
	const { cell, row, context } = params;
	const { closeTagTooltip, narrativesOnlyFlatObj, openTagTooltip, showFocus, toggleTagTooltip } = context;

	return (
		<Fragment>
			<TitleSpan dangerouslySetInnerHTML={{ __html: cell }} />
			<TagBox>
				{row.tags &&
					row.tags.map((narId) => {
						const tag = narrativesOnlyFlatObj[narId];
						if (tag) {
							return (
								<Tag
									key={`contentLink__tag-${row.uuid || row.id || row.title}-${narId}`}
									colorBlindMode={context.colorBlindMode}
									bgColor={tag.color}
									// role="button"
									aria-label={`Contains ${tag.name} narrative`}
									tabIndex="0"
									onBlur={() => closeTagTooltip()}
									onClick={(event) => toggleTagTooltip({ position: formatPosition(event.target), tag })}
									onKeyPress={(event) => clickedEnter(event) && toggleTagTooltip({ position: formatPosition(event.target), tag })}
									onMouseEnter={(event) => openTagTooltip({ position: formatPosition(event.target), tag })}
									onMouseLeave={() => closeTagTooltip()}
									showFocus={showFocus}
								/>
							);
						}
					})}
			</TagBox>
		</Fragment>
	);
};

export const addSwatch = (params) => {
	const { cell, row, context } = params;
	const { activeTabData, isolatedId } = context;

	return (
		<SwatchBox fade={isolatedId && isolatedId !== row.id}>
			{activeTabData.id === row.id && <ActiveDiamond />}
			<Swatch bgColor={row.color} />
			<HighlightSpan underlined={activeTabData.id === row.id && row.color}>{cell}</HighlightSpan>
		</SwatchBox>
	);
};

export const formatNumber = (params) => {
	const { cell, context, row } = params;
	const { isolatedId } = context;

	return <MaybeIsolate fade={isolatedId && isolatedId !== row.id}>{cell ? cell.toLocaleString() : 0}</MaybeIsolate>;
};

export const HighlightActiveRow = (props) => {
	// eslint-disable-next-line react/prop-types
	const { row, context, children } = props;
	const { activeTabData } = context;
	return <InsetShadow shadow={row.id === activeTabData.id}>{children}</InsetShadow>;
};

export const paddingLeft = (params) => {
	const { cell } = params;

	return <PaddingLeft>{cell}</PaddingLeft>;
};

export const shortDateTransform = (params) => {
	const date = params.cell;
	const { context } = params;
	const dateObj = getDateObjectFromUTC(date);
	const bgColor = context.projectTheme ? context.projectTheme.contentDate : vars.seal_3;

	return (
		<Date backgroundColor={bgColor} textColor={higherContrast({ color1: "#000000", color2: vars.sky_0_5, background: bgColor })}>
			{`${dateLib.shortMonths[dateObj.month]} ${dateObj.day}`}
		</Date>
	);
};

export const sourceTransform = (params) => {
	const { cell } = params;
	return <Source>{cell}</Source>;
};

export const sharesTransform = (params) => {
	const { cell } = params;
	return <Shares>{cell ? Number(cell).toLocaleString() : "--"}</Shares>;
};

export const transformRate = (params) => {
	const { cell, context, row } = params;
	const { isolatedId } = context;

	return (
		<Numbers fade={isolatedId && isolatedId !== row.id}>
			{cell ? (
				cell > 0 ? (
					<UpArrow>
						<Arrow fill={vars.lime_2} />
					</UpArrow>
				) : (
					<DownArrow>
						<Arrow fill={vars.tangelo_2} />
					</DownArrow>
				)
			) : (
				""
			)}
			{cell ? `${Math.round(cell * 100)}%` : "--"}
		</Numbers>
	);
};

// ---------- Label Transforms ------------
export const rateOfChangeLabel7 = (props) => {
	const { cell, row, context } = props;
	const value = Math.round(row.rate7 * 100);
	const upOrDown = value === 0 ? "unchanged" : value > 0 ? "up" : "down";
	return `Volume ${upOrDown} ${value}% over the last 7 days`;
};
export const rateOfChangeLabel30 = (props) => {
	const { cell, row, context } = props;
	const value = Math.round(row.rate30 * 100);
	const upOrDown = value === 0 ? "unchanged" : value > 0 ? "up" : "down";
	return `Volume ${upOrDown} ${value}% over the last 7 days`;
};
export const volumeLabel = (props) => {
	const { cell, row, context } = props;
	const value = row.total;
	return `${value} total volume`;
};

import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";

import Table from "@exovera/table";
import { clickedEnter, formatPosition } from "@exovera/helpers";

import vars, { focusStyle } from "@exovera/narrative-tracker-variables";
import styled from "styled-components";
// import { smallCaps } from "@exovera/style-mixins";

import { sharesTransform, shortDateTransform, sourceTransform, tagTransform } from "lib/tableTransforms.js";

// ------- Styles -------
const Root = styled.section`
	background-color: ${vars.charcoal_2_5};
	border-radius: 0 0 4px 4px;
	overflow: hidden;
`;
const MaxWidth = styled.div`
	position: relative;
	max-width: 1200px;
	margin: 0 auto;
	padding-bottom: 80px;
	@media all and (max-width: 1300px) {
		padding-bottom: 50px;
	}
	@media all and (max-width: 1250px) {
		padding-bottom: 20px;
	}
	@media all and (max-width: 1220px) {
		padding-bottom: 0;
	}
`;
const TitleBox = styled.div`
	padding: 42px 3% 22px;
	text-align: center;
`;
const Title = styled.h1`
	display: inline-block;
	padding: 8px 16px;
	${vars.mainfont};
	line-height: 130%;
	font-size: 16px;
	color: ${vars.sky_1};
	text-decoration: ${(props) => (props.hoverable ? "underline" : "none")};
	text-decoration-color: ${vars.seal_3};
	${(props) => props.hoverable && props.showFocus && focusStyle()};
	&:hover {
		color: ${(props) => (props.hoverable ? vars.sky_0_5 : vars.sky_1)};
	}
`;
const TableBox = styled.div`
	clear: both;
	max-width: 1200px;
	margin: 0 auto;
`;
const NoArticles = styled.p`
	padding: 50px 4% 30px;
	${vars.mainfontLight};
	font-size: 20px;
	text-align: center;
	color: ${vars.sky_2};
`;

// ------- Component -------
const Bottom = (props) => {
	const {
		activeTabData,
		articleTooltip,
		bottomArticles,
		bottomPhrases,
		closeInfoTooltip,
		closeTagTooltip,
		currentDateObj,
		currentFilterObj,
		narrativesOnlyFlatObj,
		openInfoTooltipWithText,
		openTagTooltip,
		showFocus,
		toggleTagTooltip,
	} = props;
	// console.log("Bottom", currentFilterObj);
	const biasString = currentFilterObj ? `, Across ${currentFilterObj.name}` : "";
	return (
		<Root>
			<MaxWidth>
				<TitleBox>
					<Title
						onMouseEnter={(event) =>
							!!articleTooltip && openInfoTooltipWithText({ position: formatPosition(event.target), text: articleTooltip })
						}
						onMouseLeave={() => !!articleTooltip && closeInfoTooltip()}
						role="button"
						tabIndex={articleTooltip ? "0" : "-1"}
						onKeyPress={(event) =>
							!!articleTooltip &&
							clickedEnter(event) &&
							openInfoTooltipWithText({ position: formatPosition(event.target), text: articleTooltip })
						}
						onBlur={() => !!articleTooltip && closeInfoTooltip()}
						hoverable={!!articleTooltip}
						showFocus={showFocus}
					>
						{`${activeTabData.name} Articles Generating Heavy Social Media Reaction the Last ${currentDateObj.totalDays} Days${biasString}`}
					</Title>
				</TitleBox>
				{bottomArticles.length > 0 ? (
					<TableBox>
						<Table
							columns={[
								{
									name: "Date",
									id: "fdate",
									width: 65,
									fixed: true,
									reverseSort: true,
									transform: shortDateTransform,
								},
								{
									name: "Source",
									id: "source",
									width: 120,
									fixed: true,
									minWidth: 380,
									transform: sourceTransform,
								},
								{
									name: "Title",
									id: "title",
									width: 10,
									fixed: false,
									transform: tagTransform,
								},
								{
									name: "Interactions",
									id: "shares",
									// width: 15,
									width: 110, // FLAG_SHOW_INTERACTIONS_AGAIN
									fixed: true,
									minWidth: 500,
									reverseSort: true,
									disableSort: true,
									transform: sharesTransform,
									// headerTransform: sharesTransform,
								},
							]}
							rows={bottomArticles}
							// clickRow={() => {}} // function required for hover state
							// clickHeader={updateContentByHeader} // use internal
							context={{
								openTagTooltip,
								closeTagTooltip,
								toggleTagTooltip,
								narrativesOnlyFlatObj,
								showFocus,
							}}
							// altHoverFunction={isolateSubject}
							// altHoverOutFunction={clearIsolation}
							defaultSort="shares"
							// defaultSort="fdate" // FLAG_SHOW_INTERACTIONS_AGAIN
							height={false} // no scrollbars
							hrefUrlTarget="_blank"
							rowID="title" // may want to use "hrefUrl" when the data is live
							showFocus={showFocus}
							verticalCenter
							// --- colors ---
							altBackgroundColor={vars.charcoal_2}
							altBackgroundColorHover={vars.charcoal_2_5}
							backgroundColor={vars.charcoal_1}
							backgroundColorHover={vars.charcoal_0_9}
							checkboxColor={"#000"}
							checkboxColorHover="#000"
							checkmarkColor={vars.cerulean_1}
							footerBackgroundColor={vars.charcoal_2_5}
							footerTextColor={vars.sky_1}
							footerHighlightTextColor={vars.sky_0_5}
							footerHighlightColor="#000000"
							loaderColor={vars.cerulean_1}
							tableBoxColor={vars.charcoal_2}
							textColor={vars.sky_1}
							textColorHover={vars.sky_0_5}
							headerColor={vars.sky_1}
							headerColorHover={vars.sky_0_5}
						/>
					</TableBox>
				) : (
					<NoArticles>No Results</NoArticles>
				)}
			</MaxWidth>
		</Root>
	);
};

Bottom.defaultProps = {};

Bottom.propTypes /* remove-proptypes */ = {
	activeTabData: PropTypes.shape({
		active: PropTypes.bool,
		color: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
	articleTooltip: PropTypes.string.isRequired,
	bottomArticles: PropTypes.arrayOf(
		PropTypes.shape({
			fdate: PropTypes.number.isRequired,
			narratives: PropTypes.arrayOf(PropTypes.string).isRequired,
			shares: PropTypes.number.isRequired,
			source: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			hrefUrl: PropTypes.string.isRequired,
		}).isRequired
	).isRequired,
	bottomPhrases: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired,
		}).isRequired
	).isRequired,
	closeInfoTooltip: PropTypes.func.isRequired,
	closeTagTooltip: PropTypes.func.isRequired,
	currentDateObj: PropTypes.shape({
		name: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		startDate: PropTypes.number.isRequired,
		endDate: PropTypes.number.isRequired,
		totalDays: PropTypes.number.isRequired,
	}).isRequired,
	currentFilterObj: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
		}),
	]).isRequired,
	openInfoTooltipWithText: PropTypes.func.isRequired,
	openTagTooltip: PropTypes.func.isRequired,
	narrativesOnlyFlatObj: PropTypes.object.isRequired,
	showFocus: PropTypes.bool.isRequired,
	toggleTagTooltip: PropTypes.func.isRequired,
};

export default Bottom;

import React from "react";
import { connect } from "react-redux";
import Bottom from "./Bottom.jsx";

import { closeInfoTooltip, closeTagTooltip, openInfoTooltipWithText, openTagTooltip, toggleTagTooltip } from "actions/ui.js";

// mapStateToProps is a react-redux specific function
// that injects the redux store, after filters. REQUIRED
//    state = reduxStore
//    ownProps = props passed to container Component (Not Meteor data)
const mapStateToProps = (state, ownProps) => {
	// console.log(state);
	// console.log(ownProps)
	return {
		activeTabData: state.data.activeTabData,
		articleTooltip: state.data.articleTooltip,
		bottomArticles: state.data.bottomArticles,
		bottomPhrases: state.data.bottomPhrases,
		currentDateObj: state.data.currentDateObj,
		currentFilterObj: state.data.currentFilterObj,
		narrativesOnlyFlatObj: state.data.narrativesOnlyFlatObj,
		showFocus: state.ui.showFocus,
	};
};

// mapDispatchToProps is a react-redux specific
// function that binds a function into the props
// Default is: dispatch => ({ dispatch }), to this isn't necessary in simple uses
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		closeInfoTooltip: () => {
			dispatch(closeInfoTooltip());
		},
		closeTagTooltip: () => {
			dispatch(closeTagTooltip(true));
		},
		openInfoTooltipWithText: (params) => {
			dispatch(openInfoTooltipWithText(params));
		},
		openTagTooltip: (params) => {
			dispatch(openTagTooltip(params));
		},
		toggleTagTooltip: (params) => {
			dispatch(toggleTagTooltip(params));
		},
	};
};

// const mergeProps = ( stateProps, actionProps, parentProps ) => ({
// ...stateProps,
// ...actionProps,
// ...parentProps
// });

// Combines everything, including Meteor data
const BottomContainer = connect(
	mapStateToProps,
	mapDispatchToProps
	// mergeProps
)(Bottom);

export default BottomContainer;

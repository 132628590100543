// All custom polyfill code to run on startup
const localPolyfill = () => {
	// // from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes
	// if (!String.prototype.includes) {
	// 	String.prototype.includes = function(search, start) {
	// 		if (typeof start !== "number") {
	// 			start = 0;
	// 		}

	// 		if (start + search.length > this.length) {
	// 			return false;
	// 		} else {
	// 			return this.indexOf(search, start) !== -1;
	// 		}
	// 	};
	// }

	// // https://tc39.github.io/ecma262/#sec-array.prototype.includes
	// if (!Array.prototype.includes) {
	// 	Object.defineProperty(Array.prototype, "includes", {
	// 		value(searchElement, fromIndex) {
	// 			if (this == null) {
	// 				throw new TypeError('"this" is null or not defined');
	// 			}

	// 			// 1. Let O be ? ToObject(this value).
	// 			const o = Object(this);

	// 			// 2. Let len be ? ToLength(? Get(O, "length")).
	// 			const len = o.length >>> 0;

	// 			// 3. If len is 0, return false.
	// 			if (len === 0) {
	// 				return false;
	// 			}

	// 			// 4. Let n be ? ToInteger(fromIndex).
	// 			//    (If fromIndex is undefined, this step produces the value 0.)
	// 			const n = fromIndex | 0;

	// 			// 5. If n ≥ 0, then
	// 			//  a. Let k be n.
	// 			// 6. Else n < 0,
	// 			//  a. Let k be len + n.
	// 			//  b. If k < 0, let k be 0.
	// 			let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

	// 			function sameValueZero(x, y) {
	// 				return (
	// 					x === y || (typeof x === "number" && typeof y === "number" && isNaN(x) && isNaN(y))
	// 				);
	// 			}

	// 			// 7. Repeat, while k < len
	// 			while (k < len) {
	// 				// a. Let elementK be the result of ? Get(O, ! ToString(k)).
	// 				// b. If SameValueZero(searchElement, elementK) is true, return true.
	// 				if (sameValueZero(o[k], searchElement)) {
	// 					return true;
	// 				}
	// 				// c. Increase k by 1.
	// 				k++;
	// 			}

	// 			// 8. Return false
	// 			return false;
	// 		},
	// 	});
	// }

	// // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/endsWith
	// if (!String.prototype.endsWith) {
	// 	String.prototype.endsWith = function(search, this_len) {
	// 		if (this_len === undefined || this_len > this.length) {
	// 			this_len = this.length;
	// 		}
	// 		return this.substring(this_len - search.length, this_len) === search;
	// 	};
	// }

	// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
	if (!String.prototype.startsWith) {
		Object.defineProperty(String.prototype, "startsWith", {
			value(search, rawPos) {
				const pos = rawPos > 0 ? rawPos | 0 : 0;
				return this.substring(pos, pos + search.length) === search;
			},
		});
	}

	if (typeof window.IntersectionObserver === "undefined") {
		require("intersection-observer");
	}
};

export default localPolyfill;

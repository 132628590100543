import React, { useRef } from "react";
import PropTypes from "prop-types";
import _ from "underscore";

import vars, { focusStyle } from "@exovera/narrative-tracker-variables";
import styled from "styled-components";
import { smallCaps } from "@exovera/style-mixins";

import { clickedEnter, higherContrast } from "@exovera/helpers";

// ------- Styles -------
const Box = styled.div`
	height: ${(props) => props.theHeight}px;
	background-color: ${(props) => props.bgColor};
	border-radius: 4px;
`;
const Button = styled.p`
	display: inline-block;
	position: relative;
	height: ${(props) => props.theHeight}px;
	width: ${(props) => props.theWidth}px;
	${smallCaps(vars.mainfontBold)};
	font-size: ${(props) => props.fontSize}px;
	text-align: center;
	padding: 5px 0 8px;
	border-radius: 4px;
	color: ${(props) => (props.active ? props.activeTextColor : props.textColor)};
	background-color: ${(props) => (props.active ? props.activeColor : "transparent")};
	cursor: ${(props) => (props.active ? "default" : "pointer")};
	pointer-events: ${(props) => (props.active ? "none" : "all")};
	vertical-align: top;
	transition: background-color 350ms ease-out 50ms;
	${(props) => props.showFocus && focusStyle()};
`;
const TextSleeve = styled.span`
	display: flex;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`;
const Text = styled.span`
	margin: auto;
	transition: none;
`;
const Spacer = styled.div`
	display: inline-block;
	height: ${(props) => props.theHeight}px;
	width: 1px;
	background-color: ${(props) => props.bgColor};
	opacity: ${(props) => (props.visible ? 1 : 0)};
`;

// ------- Component -------
const TripleToggle = (props) => {
	// console.log("TripleToggle", props);
	const { activeId, backgroundColor, blockFocus, buttonWidth, click, fontSize, height, left, center, right, showFocus } = props;
	const leftButtonRef = useRef(null);
	const centerButtonRef = useRef(null);
	const rightButtonRef = useRef(null);
	// console.log('TripleToggle', props);
	return (
		<Box bgColor={backgroundColor} theHeight={height}>
			<Button
				ref={leftButtonRef}
				active={left.id === activeId}
				activeColor={left.color}
				activeTextColor={higherContrast({
					color1: "#000000",
					color2: "#ffffff",
					background: left.color,
				})}
				fontSize={fontSize}
				textColor={vars.sky_1}
				onClick={() => {
					click(left);
					leftButtonRef.current.blur();
				}}
				role="button"
				tabIndex={left.id === activeId || blockFocus ? "-1" : "0"}
				onKeyPress={(event) => {
					if (clickedEnter(event)) {
						click(left);
						leftButtonRef.current.blur();
					}
				}}
				theHeight={height}
				theWidth={buttonWidth}
				showFocus={left.id !== activeId && showFocus}
			>
				<TextSleeve>
					<Text>{left.name}</Text>
				</TextSleeve>
			</Button>

			<Spacer theHeight={height} visible={right.id === activeId} bgColor={vars.charcoal_1} />

			<Button
				ref={centerButtonRef}
				active={center.id === activeId}
				activeColor={center.color}
				activeTextColor={higherContrast({
					color1: "#000000",
					color2: "#ffffff",
					background: center.color,
				})}
				fontSize={fontSize}
				textColor={vars.sky_1}
				onClick={() => {
					click(center);
					centerButtonRef.current.blur();
				}}
				role="button"
				tabIndex={center.id === activeId || blockFocus ? "-1" : "0"}
				onKeyPress={(event) => {
					if (clickedEnter(event)) {
						click(center);
						centerButtonRef.current.blur();
					}
				}}
				theHeight={height}
				theWidth={buttonWidth}
				showFocus={center.id !== activeId && showFocus}
			>
				<TextSleeve>
					<Text>{center.name}</Text>
				</TextSleeve>
			</Button>

			<Spacer theHeight={height} visible={left.id === activeId} bgColor={vars.charcoal_1} />

			<Button
				ref={rightButtonRef}
				active={right.id === activeId}
				activeColor={right.color}
				activeTextColor={higherContrast({
					color1: "#000000",
					color2: "#ffffff",
					background: right.color,
				})}
				fontSize={fontSize}
				textColor={vars.sky_1}
				onClick={() => {
					click(right);
					rightButtonRef.current.blur();
				}}
				role="button"
				tabIndex={right.id === activeId || blockFocus ? "-1" : "0"}
				onKeyPress={(event) => {
					if (clickedEnter(event)) {
						click(right);
						rightButtonRef.current.blur();
					}
				}}
				theHeight={height}
				theWidth={buttonWidth}
				showFocus={right.id !== activeId && showFocus}
			>
				<TextSleeve>
					<Text>{right.name}</Text>
				</TextSleeve>
			</Button>
		</Box>
	);
};

TripleToggle.defaultProps = {
	backgroundColor: vars.charcoal_3,
	blockFocus: false,
	buttonWidth: 100,
	height: 34,
	fontSize: 14,
};

TripleToggle.propTypes /* remove-proptypes */ = {
	activeId: PropTypes.string.isRequired,
	backgroundColor: PropTypes.string,
	blockFocus: PropTypes.bool,
	buttonWidth: PropTypes.number,
	height: PropTypes.number,
	fontSize: PropTypes.number,
	click: PropTypes.func.isRequired,
	left: PropTypes.shape({
		name: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
	}).isRequired,
	center: PropTypes.shape({
		name: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
	}).isRequired,
	right: PropTypes.shape({
		name: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
	}).isRequired,
	showFocus: PropTypes.bool.isRequired,
};

export default TripleToggle;

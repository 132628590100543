import React from "react";
import PropTypes from "prop-types";
// import _ from "underscore";

import { DonutChart } from "@exovera/donut-chart";

import vars from "@exovera/narrative-tracker-variables";
import styled from "styled-components";

import { clickedEnter, formatPosition, higherContrast } from "@exovera/helpers";

// ------- Styles -------
const DonutChartContainer = styled.div`
	margin: 0 auto;
	max-width: 200px;
	@media all and (max-width: 1000px) {
		max-width: 240px;
	}
`;
const PieTitle = styled.p`
	margin: -44px 0 10px;
	${vars.mainfont};
	font-size: 16px;
	text-align: center;
	color: ${vars.sky_1};
	@media all and (max-width: 1000px) {
		margin: 0 0 20px;
	}
`;
const PieSubTitle = styled.p`
	margin: 0 -30px 30px;
	${vars.mainfont};
	min-height: 16px;
	font-size: 14px;
	text-align: center;
	line-height: 120%;
	color: ${vars.sky_1};
	white-space: nowrap;
	@media all and (max-width: 1000px) {
		margin: 0 -30px 20px;
	}
`;
const PieDate = styled.p`
	margin-bottom: 10px;
	font-size: 12px;
	text-align: center;
	${vars.mainfontBold};
	color: ${vars.sky_2};
	margin-top: 20px;
`;
const PieLegend = styled.div`
	margin-top: 20px;
	width: 240px;
	margin-left: ${(props) => (props.shortLegend ? 15 : 0)}px;
`;
const LegendItem = styled.div`
	position: relative;
	padding-left: 28px;
	text-align: left;
	opacity: ${(props) => (props.fade ? 0.3 : 1)};
	outline: none;
	cursor: default;
`;
const Swatch = styled.div`
	position: absolute;
	height: 18px;
	width: 35px;
	top: 8px;
	left: 0;
	border-radius: 3px;
	background-color: ${(props) => props.bgColor};
	background-image: ${(props) =>
		props.hasPattern
			? // Stripes
			  // ? `url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%231f273d' fill-opacity='0.2' fill-rule='evenodd'%3E%3Cpath d='M0 10L10 0H5L0 5M10 10V5L5 10'/%3E%3C/g%3E%3C/svg%3E")`
			  // Dots
			  `url("data:image/svg+xml,%3Csvg width='9' height='9' viewBox='0 0 9 9' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%231f273d' fill-opacity='0.6' fill-rule='evenodd'%3E%3Ccircle cx='2.25' cy='2.25' r='1.25'/%3E%3Ccircle cx='7' cy='7' r='1.25'/%3E%3C/g%3E%3C/svg%3E")`
			: "none"};
`;
const SwatchScore = styled.p`
	padding: 4px 0 0 2px;
	font-size: 11px;
	color: ${(props) => props.textColor};
	text-align: center;
	${vars.mainfontBold};
`;
const LegendItemText = styled.p`
	margin-left: 10px;
	padding: 8px 12px;
	font-size: 14px;
	line-height: 130%;
	${vars.mainfont};
	color: ${vars.sky_0_5};
	vertical-align: middle;
	text-decoration: ${(props) => (props.hasInfo ? "underline" : "transparent")};
	text-decoration-color: ${vars.seal_3};
	text-decoration-style: dotted;
	border: 1px solid transparent;
	${LegendItem}:focus & {
		border: 1px solid ${(props) => (props.showFocus ? vars.cerulean_1 : "transparent")};
		text-decoration-color: ${(props) => (props.showFocus ? vars.cerulean_1 : vars.seal_3)};
		outline: none;
		border-radius: 3px;
		box-shadow: ${(props) => (props.showFocus ? `0 0 22px -5px ${vars.cerulean_1}` : "none")};
	}
`;

// ------- Component -------
const Pie = (props) => {
	const {
		beginMiddleIsolationPie,
		currentDateObj,
		currentFilterObj,
		dateRangeString,
		endMiddleIsolation,
		mobileSize,
		isolatedId,
		isolatedIdLegend,
		middlePie,
		showFocus,
		smallSize,
		toggleInfoTooltip,
	} = props;
	// console.log("Middle", currentFilterObj);

	const dateString = currentDateObj.id === "history" ? "Historically" : `Over the ${currentDateObj.name}`;
	const filterString = currentFilterObj ? ` Across ${currentFilterObj.name}` : "";

	return (
		<DonutChartContainer>
			<PieTitle>{middlePie.altTitle}</PieTitle>
			<PieSubTitle>{`${dateString}${filterString}`}</PieSubTitle>
			<DonutChart
				slices={middlePie.slices}
				chartId="middle"
				donutHolePercentage={mobileSize ? 50 : smallSize ? 55 : 60}
				// pieSize={smallSize ? 150 : 200}
				donutFill={middlePie.donut && vars.charcoal_1}
				fontSize={smallSize ? 12 : 14}
				clearSlice={() => endMiddleIsolation()}
				// clickSlice={(slice) => console.log("clickSlice", slice)}
				highlighted={isolatedId}
				horizontalAlignment
				hoverSlice={(slice) => beginMiddleIsolationPie(slice)}
				// isolateOnHover
				// labelPosition={0.85}
				displayPercentage
				stripeId={middlePie.opposePattern ? "anti" : ""}
				stripeFillId="svgOpposeStripe"
				crowdedNumberLimit={0.03}
				// showCrowdedNumbers
			/>
			<PieDate>{dateRangeString}</PieDate>
			<PieLegend
				smallSize={smallSize}
				mobileSize={mobileSize}
				shortLegend={middlePie.shortLegend}
				onMouseLeave={() => endMiddleIsolation()}
			>
				{middlePie.slices.map((slice) => {
					const hasInfo = !!slice.info || !!slice.header;
					const scorePercentage = Math.round(slice.value / middlePie.total * 100);
					return (
						<LegendItem
							key={slice.id}
							fade={isolatedIdLegend && isolatedIdLegend !== slice.id}
							mobileSize={mobileSize}
							role="button"
							tabIndex="0"
							onMouseEnter={() => beginMiddleIsolationPie(slice)}
							onMouseLeave={() => endMiddleIsolation()}
							onFocus={() => beginMiddleIsolationPie(slice)}
							onBlur={() => endMiddleIsolation()}
							onClick={(event) => toggleInfoTooltip({ position: formatPosition(event.target), slice })}
							onKeyPress={(event) => clickedEnter(event) && toggleInfoTooltip({ position: formatPosition(event.target), slice })}
							showFocus={props.showFocus}
						>
							<Swatch bgColor={slice.color} hasPattern={middlePie.opposePattern && slice.anti}>
								<SwatchScore textColor={higherContrast({ color1: "#000000", color2: "#ffffff", background: slice.color })}>
									{`${scorePercentage || "<1"}%`}
								</SwatchScore>
							</Swatch>
							<LegendItemText smallSize={smallSize} hasInfo={hasInfo} showFocus={showFocus}>
								{slice.name}
							</LegendItemText>
						</LegendItem>
					);
				})}
			</PieLegend>
		</DonutChartContainer>
	);
};

Pie.defaultProps = {};

Pie.propTypes /* remove-proptypes */ = {
	beginMiddleIsolationPie: PropTypes.func.isRequired,
	currentDateObj: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		// startDate: PropTypes.number.isRequired,
		// endDate: PropTypes.number.isRequired,
		// totalDays: PropTypes.number.isRequired,
	}).isRequired,
	currentFilterObj: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
		}),
	]).isRequired,
	dateRangeString: PropTypes.string.isRequired,
	endMiddleIsolation: PropTypes.func.isRequired,
	isolatedId: PropTypes.string.isRequired,
	isolatedIdLegend: PropTypes.string.isRequired,
	middlePie: PropTypes.shape({
		slices: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				value: PropTypes.number.isRequired,
				color: PropTypes.string.isRequired,
			}).isRequired
		).isRequired,
		altTitle: PropTypes.string.isRequired,
		shade: PropTypes.string,
		donut: PropTypes.bool,
		shortLegend: PropTypes.bool,
	}).isRequired,
	mobileSize: PropTypes.bool.isRequired,
	showFocus: PropTypes.bool.isRequired,
	smallSize: PropTypes.bool.isRequired,
	toggleInfoTooltip: PropTypes.func.isRequired,
};

export default Pie;

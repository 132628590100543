import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const Svg = styled.svg`
	height: 100%;
	width: 100%;
	transform: ${(props) => `scale(${props.scale})`};
`;

const Logo = (props) => {
	const { scale } = props;
	return (
		<Svg version="1.1" x="0px" y="0px" viewBox="0 0 1030 227.2678" scale={scale}>
			<polygon
				points="310.205 26.976 393.945 26.976 393.945 35.908 319.405 35.908 319.405 83.011 390.491 83.011 390.491 91.735 319.286 91.735 319.315 139.374 395.94 139.463 395.94 148.395 310.205 148.306 310.205 26.976"
				fill="#93c754"
			/>
			<polygon
				points="410.38 26.976 451.811 86.212 407.269 148.395 418.315 148.395 457.394 93.03 496.726 148.395 507.891 148.395 463.23 86.331 504.661 26.976 494.612 26.976 457.588 78.887 420.801 26.976 410.38 26.976"
				fill="#93c754"
			/>
			<polygon
				points="670.37 0 696.289 0 635.43 148.395 611.804 148.395 605.283 131.9 620.066 113.664 623.52 120.765 670.37 0"
				fill="#2b4481"
			/>
			<path
				d="M748.6419,173.2411c-7.4436-15.0658-16.1823-23.9981-30.6974-29.5808-1.3993-.5359-2.8881-1.0272-4.4661-1.4887-9.4385-2.6648-25.1593-2.8286-25.1593-2.8286-4.9575.7146-32.9305,1.7418-47.8177,29.03-16.2121,29.7744-4.6448,61.9754,2.6053,71.0863s19.919,27.1692,56.7648,24.9361,51.1822-34.7914,53.0431-47.0733S756.0855,188.3218,748.6419,173.2411Zm-4.4661,41.5948c-3.35,15.3189-16.0484,39.7637-46.7756,41.0589-29.7744,1.28-40.3889-13.3985-46.6416-21.2441s-16.3759-36.2056-2.4117-61.7818c12.8179-23.492,34.8509-24.0428,39.1236-24.6532s17.9986,0,26.5587,3.26c12.49,4.8086,20.2019,12.9221,26.6183,25.9037s5.7316,27.0054,3.4687,37.4711Z"
				transform="translate(-126 -114.1836)"
				fill="#93c754"
			/>
			<path
				d="M743.1932,218.7215l-29.6553-76.5053c-9.4534-2.6648-20.9165-3.4538-25.1891-2.8434l-1.5632.1786,42.5029,102.9A52.9533,52.9533,0,0,0,743.1932,218.7215Z"
				transform="translate(-126 -114.1836)"
				fill="#2b4481"
			/>
			<polygon
				points="693.55 27.095 784.361 27.095 784.361 49.053 716.253 49.053 716.253 77.205 781.935 77.205 782.039 98.494 716.119 98.494 716.253 126.094 784.361 126.094 784.361 148.395 693.55 148.395 693.55 27.095"
				fill="#2b4481"
			/>
			<path
				d="M1101.29,141.2486h-24.936l-50.7951,112.77-29.953-34.6127a47.46,47.46,0,0,0,14.7085-5.3892c8.7537-4.6448,14.7086-18.2368,15.8251-26.7969s-.5508-25.68-13.5771-36.28a45.0485,45.0485,0,0,0-28.4643-9.6766H924.8764V262.5642H947.758l-.1786-40.7164h21.5864l35.5506,40.7611h42.25l10.2424-23.0751,63.2407.1786,10.7932,22.8965H1156ZM947.3859,201.1993V162.4926h34.9848c7.265,0,12.7137,2.4117,16.7481,7.4436,5.2105,6.5206,5.4041,16.748,1.4887,22.5243a20.8419,20.8419,0,0,1-16.9267,8.7537Zm119.0974,16.9416,22.5244-51.3757,22.5094,51.3608Z"
				transform="translate(-126 -114.1836)"
				fill="#2b4481"
			/>
			<path
				d="M452.8,295.7647l10.9161,24.765H441.8747Zm-1.6888-3.0259-18.2374,40.6936h3.3678l4.4216-9.9948h24.2748l4.4659,9.9948h3.3679l-18.2279-40.6936Z"
				transform="translate(-126 -114.1836)"
				fill="#85a7d5"
			/>
			<path
				d="M554.4828,300.5952c-2.9642-4.7756-7.7205-5.4656-12.3094-5.5248-4.7614,0-12.31,1.74-12.31,8.25,0,5.18,5.5787,6.402,12.4227,7.6685,7.8386,1.5131,15.9777,2.9078,15.9777,11.6852-.0641,9.1864-9.2961,11.3993-16.0909,11.3993-6.3272,0-13.5306-2.617-16.667-8.8363l2.7918-1.3952c2.4914,4.9382,8.8825,7.3828,13.8752,7.3828s12.9495-1.3952,12.9495-8.6049c.0591-6.1064-6.6816-7.442-13.3541-8.7233-7.435-1.4538-15.0421-2.962-15.0421-10.463-.1774-8.595,8.77-11.2712,15.4458-11.2712,5.81,0,10.8569.9365,14.87,6.9194Z"
				transform="translate(-126 -114.1836)"
				fill="#85a7d5"
			/>
			<path
				d="M621.52,313.1484c-.059,10.6945-6.8432,20.99-20.4927,20.99-13.6438,0-20.5026-10.3546-20.5026-21.1083,0-10.927,6.8-20.926,20.5026-20.926s20.5518,10.0588,20.4927,21.0444m-37.918-.0591c.0641,9.1814,5.8642,18.1364,17.4253,18.1364,11.61,0,17.4744-8.955,17.4744-18.1364,0-9.4134-5.8051-18.0775-17.4744-18.0775-11.6742,0-17.4845,8.4819-17.4253,18.0775"
				transform="translate(-126 -114.1836)"
				fill="#85a7d5"
			/>
			<path
				d="M673.8653,300.5952c-2.9642-4.7756-7.7205-5.4656-12.31-5.5248-4.767,0-12.3094,1.74-12.3094,8.25,0,5.18,5.5689,6.402,12.4227,7.6685,7.8386,1.5131,15.9678,2.9078,15.9678,11.6852-.0591,9.1864-9.2912,11.3993-16.0811,11.3993-6.3319,0-13.53-2.617-16.6669-8.8363l2.7869-1.3952c2.4963,4.9382,8.8824,7.3828,13.88,7.3828,4.9927,0,12.9448-1.3952,12.9448-8.6049.0589-6.1064-6.6771-7.442-13.3542-8.7233-7.43-1.4538-15.0422-2.962-15.0422-10.463-.1772-8.595,8.77-11.2712,15.4508-11.2712,5.8053,0,10.8569.9365,14.8656,6.9194Z"
				transform="translate(-126 -114.1836)"
				fill="#85a7d5"
			/>
			<path
				d="M704.8953,295.36a2.2922,2.2922,0,1,1-2.2876-2.2876,2.2921,2.2921,0,0,1,2.2876,2.2876m-3.7666,38.0668h2.846V304.5944h-2.846Z"
				transform="translate(-126 -114.1836)"
				fill="#85a7d5"
			/>
			<path
				d="M796.9642,327.7875a20.9172,20.9172,0,0,1-14.8059,6.1112c-13.7029,0-20.8474-9.5956-20.9065-20.5811-.1131-10.867,6.9672-21.0392,20.9065-21.0392a20.9058,20.9058,0,0,1,14.8059,6.1014l-2.1518,1.8579a17.7774,17.7774,0,0,0-12.6541-5.17c-11.8466,0-17.9424,8.3682-17.8886,18.1364.1181,9.5314,6.1548,17.7864,17.8886,17.7864a18.1628,18.1628,0,0,0,12.7132-5.1747Z"
				transform="translate(-126 -114.1836)"
				fill="#85a7d5"
			/>
			<path
				d="M860.92,313.1484c-.05,10.6945-6.8442,20.99-20.4928,20.99-13.6437,0-20.5027-10.3546-20.5027-21.1083,0-10.9262,6.8-20.9258,20.5027-20.9258s20.5519,10.0588,20.4928,21.0444m-37.918-.0592c.0639,9.1815,5.8642,18.1365,17.4252,18.1365,11.6053,0,17.4747-8.955,17.4747-18.1365,0-9.4133-5.8053-18.0774-17.4747-18.0774-11.6741,0-17.4844,8.4819-17.4252,18.0774"
				transform="translate(-126 -114.1836)"
				fill="#85a7d5"
			/>
			<polygon
				points="795.217 183.091 779.658 206.924 778.609 206.924 762.932 183.091 762.932 219.245 759.801 219.245 759.801 178.556 763.513 178.556 779.073 202.277 794.47 178.556 798.295 178.556 798.295 219.245 795.217 219.245 795.217 183.091"
				fill="#85a7d5"
			/>
			<path
				d="M953.76,319.3629v14.0659h-3.1359V292.74h17.829c17.656,0,17.656,26.6231,0,26.6231Zm0-23.7154v20.8667h14.6927c13.5306,0,13.5306-20.8667,0-20.8667Z"
				transform="translate(-126 -114.1836)"
				fill="#85a7d5"
			/>
			<path
				d="M1038.3389,333.4274l-4.4708-9.99h-24.2742l-4.4117,9.99h-3.3777l18.2426-40.6886h3.4269l18.2328,40.6886Zm-5.692-12.8975-10.916-24.765L1010.805,320.53Z"
				transform="translate(-126 -114.1836)"
				fill="#85a7d5"
			/>
			<polygon
				points="939.71 178.556 967.071 212.218 967.071 178.556 970.149 178.556 970.149 219.245 968.916 219.245 941.507 185.649 941.507 219.245 938.379 219.245 938.379 178.556 939.71 178.556"
				fill="#85a7d5"
			/>
			<polygon
				points="1008.959 202.978 993.1 178.616 993.1 178.556 996.759 178.556 1010.525 199.956 1024.346 178.556 1028 178.556 1028 178.616 1012.096 202.978 1012.096 219.245 1008.959 219.245 1008.959 202.978"
				fill="#85a7d5"
			/>

			<polygon points="0 227.268 109.359 227.268 109.359 117.627 0 227.268" fill="#2e6fbd" />
			<polygon points="0 7.985 109.359 117.627 109.359 7.985 0 7.985" fill="#8cc94e" />
			<polygon
				points="109.359 7.985 109.359 7.985 109.359 117.627 219 117.627 109.413 7.985 109.359 7.985"
				fill="#bcde87"
			/>
			<polygon
				points="109.359 227.268 109.359 227.268 109.413 227.268 219 117.627 109.359 117.627 109.359 227.268"
				fill="#123984"
			/>
		</Svg>
	);
};

Logo.defaultProps = {
	scale: 1,
};

Logo.propTypes /* remove-proptypes */  = {
	scale: PropTypes.number,
};

export default Logo;

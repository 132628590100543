import React, { useRef } from "react";
import PropTypes from "prop-types";

import vars, { focusStyle, solidButton } from "@exovera/narrative-tracker-variables";
import styled from "styled-components";
// import { clearAfter } from "@exovera/style-mixins";
import { clickedEnter } from "@exovera/helpers";

import Logo from "assets/images/Logo.jsx";
import LogoIcon from "assets/images/LogoIcon.jsx";

// ------- Styles -------
const Root = styled.div`
	position: relative;
	height: 60px;
	padding-top: 15px;
	text-align: right;
	background-color: ${vars.charcoal_2};
	box-shadow: 0px 4px 14px -3px rgba(0, 0, 0, 0.75);
	z-index: 101;
`;
const LogoBox = styled.div`
	position: absolute;
	height: 32px;
	width: ${(props) => (props.mobileLogo ? 32 : 147)}px;
	left: 20px;
	top: 14px;
`;
const MethodologyLink = styled.div`
	display: inline-block;
	margin-right: 17px;
	padding: 6px 8px 8px;
	font-size: 14px;
	${vars.mainfontLight};
	color: ${vars.sky_1};
	text-decoration: underline;
	letter-spacing: 0.025em;
	cursor: pointer;
	&:hover {
		color: ${vars.sky_0_5};
	}
	border: 1px solid transparent;
	${(props) => props.showFocus && focusStyle()};
`;
const ContactUs = styled.a`
	display: inline-block;
	margin-right: 10px;
	${solidButton(vars.sosi_purple_1, vars.sosi_purple_2, "#ffffff")};
	${vars.mainfontBold};
	width: 145px;
	border: 1px solid transparent;
	${(props) => props.showFocus && focusStyle()};
	@media all and (max-width: 470px) {
		font-size: 12px;
		width: 105px;
		margin: 1px 15px 0 0;
	}
`;

// ------- Component -------
const Navbar = (props) => {
	const methodologyRef = useRef(null);
	const { openMethodology, showFocus, windowSize } = props;
	// console.log('Navbar', props);
	const mobileLogo = windowSize.width < 420;

	return (
		<Root>
			<LogoBox mobileLogo={mobileLogo}>{mobileLogo ? <LogoIcon /> : <Logo />}</LogoBox>

			<MethodologyLink
				ref={methodologyRef}
				role="button"
				tabIndex="0"
				showFocus={showFocus}
				onKeyPress={(event) => {
					if (clickedEnter(event)) {
						methodologyRef.current.blur();
						openMethodology();
					}
				}}
				onClick={() => {
					methodologyRef.current.blur();
					openMethodology();
				}}
			>
				About
			</MethodologyLink>

			<ContactUs href="http://www.exovera.com/" target="_blank" showFocus={showFocus}>
				Contact Us
			</ContactUs>
		</Root>
	);
};

Navbar.defaultProps = {};

Navbar.propTypes /* remove-proptypes */ = {
	openMethodology: PropTypes.func.isRequired,
	showFocus: PropTypes.bool.isRequired,
	windowSize: PropTypes.shape({
		height: PropTypes.number.isRequired,
		width: PropTypes.number.isRequired,
	}).isRequired,
};

export default Navbar;

import React from "react";
import { connect } from "react-redux";
import Middle from "./Middle.jsx";

import { clickBottomTab, setMiddleData } from "actions/data.js";
import { beginIsolationTable, beginMiddleIsolationPie, endMiddleIsolation, toggleInfoTooltip } from "actions/ui.js";

// mapStateToProps is a react-redux specific function
// that injects the redux store, after filters. REQUIRED
//    state = reduxStore
//    ownProps = props passed to container Component (Not Meteor data)
const mapStateToProps = (state, ownProps) => {
	// console.log(state);
	// console.log(ownProps)
	return {
		activeTabData: state.data.activeTabData,
		browserType: state.ui.browserType,
		bottomTabs: state.data.bottomTabs,
		currentDateObj: state.data.currentDateObj,
		currentFilterObj: state.data.currentFilterObj,
		dataOptions: state.data.dataOptions,
		dateRangeString: state.data.dateRangeString,
		isolatedId: state.ui.isolatedId,
		isolatedIdLegend: state.ui.isolatedIdLegend,
		isolatedIdTable: state.ui.isolatedIdTable,
		selectedDataOption: state.data.selectedDataOption,
		middlePie: state.data.middlePie,
		middleGraph: state.data.middleGraph,
		middleTable: state.data.middleTable,
		showFocus: state.ui.showFocus,
		topToggleActive: state.data.topToggleActive,
		visibleAnnotations: state.data.visibleAnnotations,
		windowSize: state.ui.windowSize,
	};
};

// mapDispatchToProps is a react-redux specific
// function that binds a function into the props
// Default is: dispatch => ({ dispatch }), to this isn't necessary in simple uses
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		beginMiddleIsolationPie: (params) => {
			dispatch(beginMiddleIsolationPie(params));
		},
		beginIsolationTable: (params) => {
			dispatch(beginIsolationTable(params));
		},
		clickBottomTab: (tab) => {
			dispatch(clickBottomTab(tab));
		},
		endMiddleIsolation: () => {
			dispatch(endMiddleIsolation());
		},
		setMiddleData: (option) => {
			dispatch(setMiddleData(option));
		},
		toggleInfoTooltip: (params) => {
			dispatch(toggleInfoTooltip(params));
		},
	};
};

// const mergeProps = ( stateProps, actionProps, parentProps ) => ({
// ...stateProps,
// ...actionProps,
// ...parentProps
// });

// Combines everything, including Meteor data
const MiddleContainer = connect(
	mapStateToProps,
	mapDispatchToProps
	// mergeProps
)(Middle);

export default MiddleContainer;

import React from "react";
import PropTypes from "prop-types";
// import _ from "underscore";

import { clickedEnter } from "@exovera/helpers";

import vars, { focusStyle } from "@exovera/narrative-tracker-variables";
import styled from "styled-components";
// import { smallCaps } from "@exovera/style-mixins";

// ------- Styles -------
const ScrollbarMask = styled.div`
	height: 49px;
	overflow: hidden;
`;
const Scrollable = styled.div`
	display: flex;
	align-items: flex-end;
	overflow-x: auto;
	padding: 23px 20px 17px; /* top padding required to accomodate focus glow */
`;
const Tab = styled.div`
	margin: 0 auto;
	padding: 6px 14px 0;
	max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "100%")};
	min-width: ${(props) => (props.minWidth && !props.scrollMode ? `${props.minWidth}px` : "auto")};
	white-space: nowrap;
	width: ${(props) => (props.scrollMode ? "auto" : `${props.widthPercentage}%`)};
	cursor: pointer;
	outline: none;
	border: solid 1px transparent;
	${(props) => props.showFocus && focusStyle()};
`;
const Text = styled.p`
	${vars.mainfontBold};
	padding-bottom: 3px;
	font-size: 14px;
	text-align: center;
	color: ${(props) => props.textColor};
	&:hover {
		color: ${(props) => props.hoverColor};
	}
`;
const Line = styled.div`
	position: relative;
	height: 2px;
	width: calc(100% + 14px);
	left: -7px;
	background-color: ${(props) => props.underlineColor};
	opacity: ${(props) => (props.active ? 1 : 0)};
`;

// ------- Component -------
const Tabs = (props) => {
	const { activeTextColor, hoverColor, clickTab, maxWidth, minWidth, showFocus, tabs, textColor, underlineColor, windowSize } = props;
	// console.log('Tabs', props);
	const scrollMode = minWidth * tabs.length > windowSize.width;

	return (
		<ScrollbarMask>
			<Scrollable>
				{tabs.map((tab) => {
					return (
						<Tab
							key={tab.id}
							maxWidth={maxWidth}
							minWidth={minWidth}
							widthPercentage={Math.round(1 / tabs.length * 100)}
							onClick={() => clickTab(tab)}
							scrollMode={scrollMode}
							role="button"
							tabIndex={tab.active ? "-1" : "0"}
							onKeyPress={(event) => clickedEnter(event) && clickTab(tab)}
							showFocus={!tab.active && showFocus}
						>
							<Text textColor={tab.active ? activeTextColor : textColor} hoverColor={tab.active ? activeTextColor : hoverColor}>
								{tab.name}
							</Text>
							<Line active={tab.active} underlineColor={tab.color || underlineColor} />
						</Tab>
					);
				})}
			</Scrollable>
		</ScrollbarMask>
	);
};

Tabs.defaultProps = {
	maxWidth: false,
	minWidth: false,
	showFocus: false,
	textColor: vars.sky_1,
	underlineColor: vars.sky_2,
};

Tabs.propTypes /* remove-proptypes */ = {
	clickTab: PropTypes.func.isRequired,
	maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]), // Prevents super wide underline on large screens
	minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]), // Enables the scrollbar. Otherwise, the tab text wraps
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			active: PropTypes.bool,
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			color: PropTypes.string,
		}).isRequired
	).isRequired,
	// Colors
	activeTextColor: PropTypes.string,
	hoverColor: PropTypes.string,
	showFocus: PropTypes.bool,
	textColor: PropTypes.string,
	underlineColor: PropTypes.string,
	windowSize: PropTypes.shape({
		height: PropTypes.number.isRequired,
		width: PropTypes.number.isRequired,
	}).isRequired,
};

export default Tabs;

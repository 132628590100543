import React from "react";
import PropTypes from "prop-types";

import vars from "@exovera/narrative-tracker-variables";
import styled from "styled-components";

import { X } from "@exovera/icons-lite";

const Base = styled.section`
	display: ${(props) => (props.hide ? "none" : "block")};
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	padding: 15px 4%;
	background-color: ${vars.exoveraGreen};
	letter-spacing: 0.01em;

	box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.75);
	z-index: ${(props) => props.zIndex};
`;
const Header = styled.h1`
	max-width: 800px;
	margin: 0 auto 5px;
	font-size: 22px;
	line-height: 140%;
	${vars.mainfont};
	text-align: center;
	color: #fff;
`;
const Text = styled.p`
	line-height: 170%;
	max-width: 800px;
	margin: 0 auto;
	${vars.mainfont};
	font-size: 14px;
	text-align: center;
	color: #fff;
	a {
		color: #fff;
		text-decoration: underline;
		&:hover {
			opacity: 0.8;
		}
	}
`;
const XBox = styled.div`
	position: absolute;
	height: 24px;
	width: 24px;
	right: 20px;
	top: calc(50% - 12px);
	opacity: 0.6;
	cursor: pointer;
	&:hover {
		opacity: 1;
	}
`;

class Notification extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			cleared: false,
		};

		this.clearNotification = this.clearNotification.bind(this);
	}

	clearNotification() {
		this.setState({
			cleared: true,
		});
	}
	render() {
		const { props, state } = this;
		const { cleared } = state;
		const { notification, externalClose, zIndex } = props;

		return (
			<Base className={`notification ${notification.notificationType}`} zIndex={zIndex} hide={cleared}>
				<Header dangerouslySetInnerHTML={{ __html: notification.title }} />
				<Text dangerouslySetInnerHTML={{ __html: notification.notificationMessage }} />
				<XBox onClick={() => (externalClose ? externalClose() : this.clearNotification())}>
					<X fill="#ffffff" />
				</XBox>
			</Base>
		);
	}
}

Notification.defaultProps = {
	externalClose: false,
	zIndex: 1,
};

Notification.propTypes = {
	notification: PropTypes.shape({
		notificationDate: PropTypes.string,
		notificationMessage: PropTypes.string,
		notificationType: PropTypes.string,
		title: PropTypes.string,
		uuid: PropTypes.string,
	}).isRequired,
	externalClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	zIndex: PropTypes.number,
};

export default Notification;

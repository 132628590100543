/* eslint no-tabs: "off", indent: "off" */

import {
	CLICK_FILTER_TOGGLE,
	CLICK_TOP_TOGGLE,
	DATE_SELECT,
	REQUESTING_DATA,
	RECEIVE_DATA,
	SET_BOTTOM_DATA,
	SET_MIDDLE_DATA,
} from "actions/constants.js";

import _ from "underscore";
import vars from "@exovera/narrative-tracker-variables";
import { getDateObjectFromUTC, replaceById } from "@exovera/helpers";
import {
	nestTopics,
	hydrateTopics,
	filterAnnotations,
	filterArticles,
	flatNarrativesOnly,
	formatDataOptions,
	formatGraph,
	formatPie,
	formatTable,
	selectTabs,
} from "lib/dataHelpers.js";
import { log } from "lib/helpers.js";
import dateLib from "lib/dateLib.js";

const initialState = {
	apiCall: {},

	// Static data
	dataReady: false,
	fetchingData: false,

	dateRanges: [],
	filters: {},
	title: "",
	topics: {},
	currentFilterId: "a",
	currentFilterObj: {},
	currentDateId: "7days", // will be replaced by first date
	currentDateObj: {},

	currentData: {},
	storedData: {},
	historicData: {},
	annotations: [],
	articles: [],
	articleTooltip: "",
	masterTimeseries: {},
	narrativesOnlyFlatObj: {},
	socialTitle: "",
	socialText: "",
	socialHashtags: [],
	socialTwitter: [],
	topicsFlatObj: {},
	visibleAnnotations: [], // These are only affected by filters - they won't appear unless they match the topic/narrative lines' ids, or are out of the date range

	// Top
	dateRangeString: "",
	totalArticles: 0,
	topToggle: { left: {}, right: {}, both: {} },
	topToggleActive: "overall",
	dataSlice: false, // false or "left" or "right". Used to grab the topics for the top pie charts
	centerPie: {},
	leftPie: {},
	rightPie: {},

	// Middle
	dataOptions: {
		left: {
			name: "Support",
			id: "support",
			color: vars.sky_1,
		},
		center: {
			name: "Oppose",
			id: "oppose",
			color: vars.sky_1,
		},
		right: {
			name: "All",
			id: "all",
			color: vars.sky_1,
		},
	},
	selectedDataOption: {
		name: "All",
		id: "all",
		color: vars.sky_1,
	},
	middlePie: {},
	middleGraph: {},
	middleTable: {},

	// Bottom
	activeTabData: {},
	bottomTabs: [],
	bottomArticles: [],
	bottomPhrases: [],
};

const dataReducer = (state = initialState, action) => {
	switch (action.type) {
		case CLICK_FILTER_TOGGLE: {
			const { filter } = action;
			const {
				activeTabData,
				annotations,
				articles,
				currentDateObj,
				currentDateId,
				dataSlice,
				masterTimeseries,
				selectedDataOption,
				storedData,
				topics,
			} = state;
			const filters = JSON.parse(JSON.stringify(state.filters));
			// console.log("CLICK_FILTER_TOGGLE", filter);
			const subject = dataSlice && topics[dataSlice];

			const data = storedData[`${filter.id}${currentDateId}`];
			const historicData = storedData[`${filter.id}history`];

			return Object.assign({}, state, {
				filters,
				currentFilterId: filter.id,
				currentFilterObj: filter,

				// Top
				centerPie: formatPie({
					data,
					topics,
					subject,
				}),
				leftPie: formatPie({
					data,
					topics,
					subject: !dataSlice ? topics.left : topics[dataSlice].support,
				}),
				rightPie: formatPie({
					data,
					topics,
					subject: !dataSlice ? topics.right : topics[dataSlice].oppose,
				}),
				totalArticles: dataSlice ? data[topics[dataSlice].id].total : data.total,

				middlePie: formatPie({
					data,
					topics,
					subject: selectedDataOption.subject || subject,
				}),
				middleGraph: formatGraph({
					masterTimeseries,
					currentDateObj,
					topics,
					biasId: filter.id,
					subject: selectedDataOption.subject || subject,
				}),
				middleTable: formatTable({
					data,
					historicData,
					topics,
					subject: selectedDataOption.subject || subject,
				}),
				visibleAnnotations: filterAnnotations({ annotations, biasId: filter.id }),

				bottomArticles: filterArticles({ articles, tabId: activeTabData.id, dateObj: currentDateObj, biasId: filter.id }),
				bottomPhrases: data[activeTabData.id].phrases,
			});
		}

		case CLICK_TOP_TOGGLE: {
			const { id, dataSlice } = action.toggle;
			const { currentData, topics, masterTimeseries, articles, currentDateObj, currentFilterId, historicData, topicsFlatObj } = state;

			const subject = dataSlice && topics[dataSlice];
			const bottomTabs = selectTabs({
				data: currentData,
				topics,
				subject,
			});
			const activeTabData = _.find(bottomTabs, (tab) => {
				return tab.active;
			});

			return Object.assign({}, state, {
				topToggleActive: id,
				dataSlice,
				centerPie: formatPie({
					data: currentData,
					topics,
					subject,
				}),
				leftPie: formatPie({
					data: currentData,
					topics,
					subject: !dataSlice ? topics.left : topics[dataSlice].support,
				}),
				rightPie: formatPie({
					data: currentData,
					topics,
					subject: !dataSlice ? topics.right : topics[dataSlice].oppose,
				}),
				totalArticles: dataSlice ? currentData[topics[dataSlice].id].total : currentData.total,

				dataOptions: {
					left: {
						name: "Support",
						id: "support",
						color: vars.sky_1,
						subject: topicsFlatObj[`${id}sa`],
					},
					center: {
						name: "Oppose",
						id: "oppose",
						color: vars.sky_1,
						subject: topicsFlatObj[`${id}oa`],
					},
					right: {
						name: "All",
						id: "all",
						color: vars.sky_1,
						subject: topicsFlatObj[id],
					},
				},
				selectedDataOption: {
					name: "All",
					id: "all",
					color: vars.sky_1,
					subject: topicsFlatObj[id],
				},
				middlePie: formatPie({
					data: currentData,
					topics,
					subject,
				}),
				middleGraph: formatGraph({
					masterTimeseries,
					currentDateObj,
					topics,
					biasId: currentFilterId,
					subject,
				}),
				middleTable: formatTable({
					data: currentData,
					historicData,
					topics,
					subject,
				}),

				activeTabData,
				bottomTabs,
				bottomArticles: filterArticles({ articles, tabId: activeTabData.id, dateObj: currentDateObj, biasId: currentFilterId }),
				bottomPhrases: state.currentData[activeTabData.id].phrases,
			});
		}

		case DATE_SELECT: {
			const { dateObj } = action;
			const { activeTabData, articles, currentFilterId, dataSlice, masterTimeseries, selectedDataOption, storedData, topics } = state;
			const dateId = dateObj.id;
			const data = storedData[`${currentFilterId}${dateId}`];
			const historicData = storedData[`${currentFilterId}history`];
			const subject = dataSlice && topics[dataSlice];

			const dateBeginObj = getDateObjectFromUTC(dateObj.startDate);
			const dateEndObj = getDateObjectFromUTC(dateObj.endDate);

			return Object.assign({}, state, {
				currentDateId: dateId,
				currentDateObj: dateObj,
				currentData: data,

				// Top
				dateRangeString: `${dateLib.shortMonths[dateBeginObj.month]} ${dateBeginObj.day} - ${dateLib.shortMonths[dateEndObj.month]} ${
					dateEndObj.day
				}`,

				centerPie: formatPie({
					data,
					topics,
					subject,
				}),
				leftPie: formatPie({
					data,
					topics,
					subject: !dataSlice ? topics.left : topics[dataSlice].support,
				}),
				rightPie: formatPie({
					data,
					topics,
					subject: !dataSlice ? topics.right : topics[dataSlice].oppose,
				}),
				totalArticles: dataSlice ? data[topics[dataSlice].id].total : data.total,

				middlePie: formatPie({
					data,
					topics,
					subject: selectedDataOption.subject || subject,
				}),
				middleGraph: formatGraph({
					masterTimeseries,
					currentDateObj: dateObj,
					topics,
					biasId: currentFilterId,
					subject: selectedDataOption.subject || subject,
				}),
				middleTable: formatTable({
					data,
					historicData,
					topics,
					subject: selectedDataOption.subject || subject,
				}),

				bottomArticles: filterArticles({ articles, tabId: activeTabData.id, dateObj, biasId: currentFilterId }),
				bottomPhrases: data[activeTabData.id].phrases,
			});
		}

		case RECEIVE_DATA: {
			const { response } = action;
			const { annotations, articles, stats, timeseries, setup } = response;
			log(["RECEIVE_DATA", response]);
			const { articleTooltip, dateRanges, filters, shortTitle, title, socialTitle, socialText, socialHashtags, socialTwitter } = setup;

			const defaultDateKey = dateRanges && dateRanges[0] && dateRanges[0].id;
			const defaultDateBeginObj = dateRanges && dateRanges[0] && getDateObjectFromUTC(dateRanges[0].startDate);
			const defaultDateEndObj = dateRanges && dateRanges[0] && getDateObjectFromUTC(dateRanges[0].endDate);

			const topics = nestTopics(JSON.parse(JSON.stringify(setup.topics)));
			const currentData = stats[`a${defaultDateKey}`];
			const historicData = stats.ahistory;

			const bottomTabs = selectTabs({
				data: currentData,
				topics,
				subject: false,
			});
			const activeTabData = _.find(bottomTabs, (tab) => {
				return tab.active;
			});

			const currentFilterObj = { id: "a", name: "All Media", color: vars.sky_1 };
			filters.left = filters.filtersArray[0];
			filters.right = filters.filtersArray[1];
			filters.left.color = vars.sky_1;
			filters.right.color = vars.sky_1;
			filters.both = currentFilterObj;

			// console.log("topics", topics);
			return Object.assign({}, state, {
				// apiCall: response, // FLAG_DOWNLOAD_MOCK_CALL
				// --- Static data ---
				annotations,
				articles,
				// articleTooltip:
				// 	"Exovera Narratives measures the degree to which media content contains various narratives. It uses machine learning AI to classify content by narrative, and measures audience reaction to that content on social media.",
				articleTooltip,
				dataReady: true,
				dateRanges,
				masterTimeseries: timeseries,
				narrativesOnlyFlatObj: flatNarrativesOnly(topics),
				socialTitle: "Exovera's Media Narrative Tracker: Trump v. Biden",
				socialText:
					"Exovera Narratives measures the degree to which media content contains various narratives. It uses machine learning AI to classify content by narrative, and measures audience reaction to that content on social media.",
				socialHashtags: ["2020election", "MediaNarratives"],
				socialTwitter: ["Exovera"],
				// socialTitle,
				// socialText,
				// socialHashtags,
				// socialTwitter,
				storedData: stats,
				topToggle: {
					left: {
						name: topics.left.name,
						id: topics.left.id,
						color: topics.left.color,
						dataSlice: "left",
					},
					right: {
						name: topics.right.name,
						id: topics.right.id,
						color: topics.right.color,
						dataSlice: "right",
					},
					both: {
						name: "both",
						id: "overall",
						color: vars.sky_1,
						dataSlice: false,
					},
				},
				title,
				topics,
				topicsFlatObj: hydrateTopics({ flatTopics: setup.topics, topics }),

				// All
				fetchingData: false,
				currentData,
				currentDateId: defaultDateKey,
				currentDateObj: dateRanges[0],
				currentFilterId: currentFilterObj.id,
				currentFilterObj,
				filters, // Not static, due to the active state
				historicData,

				// Top
				dateRangeString: `${dateLib.shortMonths[defaultDateBeginObj.month]} ${defaultDateBeginObj.day} - ${
					dateLib.shortMonths[defaultDateEndObj.month]
				} ${defaultDateEndObj.day}`,
				totalArticles: currentData.total,

				centerPie: formatPie({
					data: currentData,
					topics,
					subject: false,
				}),
				leftPie: formatPie({
					data: currentData,
					topics,
					subject: topics.left,
				}),
				rightPie: formatPie({
					data: currentData,
					topics,
					subject: topics.right,
				}),

				// Middle
				middlePie: formatPie({
					data: currentData,
					topics,
					subject: false,
				}),
				middleGraph: formatGraph({
					masterTimeseries: timeseries,
					currentDateObj: dateRanges[0],
					topics,
					biasId: state.currentFilterId,
					subject: false,
				}),
				middleTable: formatTable({
					data: currentData,
					historicData,
					topics,
					subject: false,
				}),
				visibleAnnotations: filterAnnotations({ annotations, biasId: state.currentFilterId }),

				activeTabData,
				bottomTabs,
				bottomArticles: filterArticles({ articles, tabId: activeTabData.id, dateObj: dateRanges[0], biasId: state.currentFilterId }),
				bottomPhrases: currentData[activeTabData.id].phrases,
			});
		}

		case REQUESTING_DATA: {
			return Object.assign({}, state, {
				fetchingData: true,
			});
		}

		case SET_MIDDLE_DATA: {
			const { articles, currentData, currentFilterId, currentDateObj, historicData, masterTimeseries, topics } = state;
			// console.log("SET_MIDDLE_DATA", action);

			const bottomTabs = selectTabs({
				data: state.currentData,
				topics: state.topics,
				subject: action.option.subject,
			});
			const activeTabData = _.find(bottomTabs, (tab) => {
				return tab.active;
			});

			return Object.assign({}, state, {
				selectedDataOption: action.option,
				middlePie: formatPie({
					data: currentData,
					topics,
					subject: action.option.subject,
				}),
				middleGraph: formatGraph({
					masterTimeseries,
					currentDateObj,
					topics,
					biasId: currentFilterId,
					subject: action.option.subject,
				}),
				middleTable: formatTable({
					data: currentData,
					historicData,
					topics,
					subject: action.option.subject,
				}),

				activeTabData,
				bottomTabs,
				bottomArticles: filterArticles({ articles, tabId: activeTabData.id, dateObj: currentDateObj, biasId: currentFilterId }),
				bottomPhrases: state.currentData[activeTabData.id].phrases,
			});
		}

		case SET_BOTTOM_DATA: {
			// console.log("SET_BOTTOM_DATA", action);
			const { articles, currentDateObj, currentFilterId } = state;
			const { tab } = action;
			tab.active = true;

			// Turn the tabs off, then select the active one
			const bottomTabs = JSON.parse(JSON.stringify(state.bottomTabs));
			_.each(bottomTabs, (obj) => {
				obj.active = false;
			});

			return Object.assign({}, state, {
				activeTabData: tab,
				bottomTabs: replaceById({ array: bottomTabs, id: tab.id, replacementObj: tab }),
				bottomArticles: filterArticles({ articles, tabId: tab.id, dateObj: currentDateObj, biasId: currentFilterId }),
				bottomPhrases: state.currentData[tab.id].phrases,
			});
		}

		default:
			return state;
	}
};

export default dataReducer;

import {
	BEGIN_MIDDLE_ISOLATION_PIE,
	BEGIN_ISOLATION_TABLE,
	BEGIN_MIDDLE_ISOLATION_LEGEND,
	BEGIN_TOP_ISOLATION_LEGEND,
	BEGIN_TOP_ISOLATION_PIE,
	CLOSE_INFO_TOOLTIP,
	CLOSE_METHODOLOGY,
	CLOSE_TAG_TOOLTIP,
	END_MIDDLE_ISOLATION,
	END_TOP_ISOLATION,
	OPEN_INFO_TOOLTIP,
	OPEN_INFO_TOOLTIP_WITH_TEXT,
	OPEN_METHODOLOGY,
	OPEN_TAG_TOOLTIP,
	SET_BROWSER_TYPE,
	SET_MIDDLE_ISOLATION_FLAG,
	SET_TOP_ISOLATION_FLAG,
} from "actions/constants.js";

// Set window dimentions for the whole app
export function setWindowSize(windowSize) {
	return {
		type: "setWindowSize",
		windowSize,
	};
}

export const setBrowserType = (browserType) => {
	return {
		type: SET_BROWSER_TYPE,
		browserType,
	};
};

export function putFocusBack() {
	return {
		type: "putFocusBack",
	};
}

export const closeTagTooltip = () => {
	return {
		type: CLOSE_TAG_TOOLTIP,
	};
};
export const openTagTooltip = (params) => {
	const { position, tag } = params;
	return {
		type: OPEN_TAG_TOOLTIP,
		position,
		tag,
	};
};
export const toggleTagTooltip = (params) => {
	const { position, tag } = params;
	return (dispatch, getState) => {
		const { tagTooltipOpen } = getState().ui;
		dispatch({
			type: tagTooltipOpen ? CLOSE_TAG_TOOLTIP : OPEN_TAG_TOOLTIP,
			position,
			tag,
		});
	};
};

export const closeInfoTooltip = () => {
	return {
		type: CLOSE_INFO_TOOLTIP,
	};
};
export const openInfoTooltip = (params) => {
	const { position, slice } = params;
	return {
		type: OPEN_INFO_TOOLTIP,
		position,
		slice,
	};
};
export const openInfoTooltipWithText = (params) => {
	const { position, text } = params;
	return {
		type: OPEN_INFO_TOOLTIP_WITH_TEXT,
		position,
		text,
	};
};
export const toggleInfoTooltip = (params) => {
	const { position, slice } = params;
	return (dispatch, getState) => {
		const { infoTooltipOpen } = getState().ui;
		dispatch({
			type: infoTooltipOpen ? CLOSE_INFO_TOOLTIP : OPEN_INFO_TOOLTIP,
			position,
			slice,
		});
	};
};

export const closeMethodology = () => {
	return {
		type: CLOSE_METHODOLOGY,
	};
};
export const openMethodology = () => {
	return {
		type: OPEN_METHODOLOGY,
	};
};

export const beginTopIsolationPie = (params) => {
	const { slice, side } = params;
	return (dispatch, getState) => {
		const { showFocus } = getState().ui;
		dispatch({ type: SET_TOP_ISOLATION_FLAG, isolationFlag: slice.id, side });
		setTimeout(() => {
			dispatch({
				type: BEGIN_TOP_ISOLATION_PIE,
				isolate: slice.id,
			});
		}, showFocus ? 50 : 150);
	};
};
export const beginTopIsolationLegend = (params) => {
	const { position, slice, side } = params;
	return (dispatch, getState) => {
		const { showFocus } = getState().ui;
		dispatch({ type: SET_TOP_ISOLATION_FLAG, isolationFlag: slice.id, side });
		dispatch(openInfoTooltip({ position, slice }));
		setTimeout(() => {
			dispatch({
				type: BEGIN_TOP_ISOLATION_LEGEND,
				isolate: slice.id,
			});
		}, showFocus ? 50 : 150);
	};
};
export const endTopIsolation = () => {
	return (dispatch, getState) => {
		const { showFocus } = getState().ui;
		dispatch({ type: SET_TOP_ISOLATION_FLAG, isolationFlag: "" });
		dispatch(closeInfoTooltip());
		setTimeout(() => {
			dispatch({ type: END_TOP_ISOLATION });
		}, showFocus ? 50 : 150);
	};
};

export const beginMiddleIsolationPie = (params) => {
	const { id } = params;
	return (dispatch, getState) => {
		const { showFocus } = getState().ui;
		dispatch({ type: SET_MIDDLE_ISOLATION_FLAG, isolationFlag: id });
		setTimeout(() => {
			dispatch({
				type: BEGIN_MIDDLE_ISOLATION_PIE,
				isolate: id,
			});
		}, showFocus ? 50 : 150);
	};
};
export const beginIsolationTable = (params) => {
	const { id } = params;
	return (dispatch, getState) => {
		const { showFocus } = getState().ui;
		dispatch({ type: SET_MIDDLE_ISOLATION_FLAG, isolationFlag: id });
		setTimeout(() => {
			dispatch({
				type: BEGIN_ISOLATION_TABLE,
				isolate: id,
			});
		}, showFocus ? 50 : 150);
	};
};

export const endMiddleIsolation = () => {
	return (dispatch, getState) => {
		const { showFocus } = getState().ui;
		dispatch({ type: SET_MIDDLE_ISOLATION_FLAG, isolationFlag: "" });
		dispatch(closeInfoTooltip());
		setTimeout(() => {
			dispatch({ type: END_MIDDLE_ISOLATION });
		}, showFocus ? 50 : 150);
	};
};

import React from "react";
import { connect } from "react-redux";
import Top from "./Top.jsx";

import { clickTopToggle } from "actions/data.js";
import { beginTopIsolationLegend, beginTopIsolationPie, endTopIsolation, toggleInfoTooltip } from "actions/ui.js";

// mapStateToProps is a react-redux specific function
// that injects the redux store, after filters. REQUIRED
//    state = reduxStore
//    ownProps = props passed to container Component (Not Meteor data)
const mapStateToProps = (state, ownProps) => {
	// console.log(state);
	// console.log(ownProps)
	return {
		browserType: state.ui.browserType,
		centerPie: state.data.centerPie,
		currentData: state.data.currentData,
		currentDateObj: state.data.currentDateObj,
		currentFilterObj: state.data.currentFilterObj,
		dateRangeString: state.data.dateRangeString,
		leftPie: state.data.leftPie,
		rightPie: state.data.rightPie,
		showFocus: state.ui.showFocus,
		topIsolatedId: state.ui.topIsolatedId,
		topIsolatedIdLegend: state.ui.topIsolatedIdLegend,
		topIsolatedSide: state.ui.topIsolatedSide,
		topToggle: state.data.topToggle,
		topToggleActive: state.data.topToggleActive,
		totalArticles: state.data.totalArticles,
		windowSize: state.ui.windowSize,
	};
};

// mapDispatchToProps is a react-redux specific
// function that binds a function into the props
// Default is: dispatch => ({ dispatch }), to this isn't necessary in simple uses
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		beginTopIsolationLegend: (params) => {
			dispatch(beginTopIsolationLegend(params));
		},
		beginTopIsolationPie: (params) => {
			dispatch(beginTopIsolationPie(params));
		},
		clickTopToggle: (params) => {
			dispatch(clickTopToggle(params));
		},
		endTopIsolation: () => {
			dispatch(endTopIsolation());
		},
		toggleInfoTooltip: (params) => {
			dispatch(toggleInfoTooltip(params));
		},
	};
};

// const mergeProps = ( stateProps, actionProps, parentProps ) => ({
// ...stateProps,
// ...actionProps,
// ...parentProps
// });

// Combines everything, including Meteor data
const TopContainer = connect(
	mapStateToProps,
	mapDispatchToProps
	// mergeProps
)(Top);

export default TopContainer;

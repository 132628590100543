export const BEGIN_MIDDLE_ISOLATION_PIE = "BEGIN_MIDDLE_ISOLATION_PIE";
export const BEGIN_ISOLATION_TABLE = "BEGIN_ISOLATION_TABLE";
export const BEGIN_MIDDLE_ISOLATION_LEGEND = "BEGIN_MIDDLE_ISOLATION_LEGEND";
export const BEGIN_TOP_ISOLATION_LEGEND = "BEGIN_TOP_ISOLATION_LEGEND";
export const BEGIN_TOP_ISOLATION_PIE = "BEGIN_TOP_ISOLATION_PIE";
export const CLICK_FILTER_TOGGLE = "CLICK_FILTER_TOGGLE";
export const CLICK_TOP_TOGGLE = "CLICK_TOP_TOGGLE";
export const CLOSE_INFO_TOOLTIP = "CLOSE_INFO_TOOLTIP";
export const CLOSE_METHODOLOGY = "CLOSE_METHODOLOGY";
export const CLOSE_TAG_TOOLTIP = "CLOSE_TAG_TOOLTIP";
export const DATE_SELECT = "DATE_SELECT";
export const END_MIDDLE_ISOLATION = "END_MIDDLE_ISOLATION";
export const END_TOP_ISOLATION = "END_TOP_ISOLATION";
export const OPEN_INFO_TOOLTIP = "OPEN_INFO_TOOLTIP";
export const OPEN_INFO_TOOLTIP_WITH_TEXT = "OPEN_INFO_TOOLTIP_WITH_TEXT";
export const OPEN_METHODOLOGY = "OPEN_METHODOLOGY";
export const OPEN_TAG_TOOLTIP = "OPEN_TAG_TOOLTIP";
export const RECEIVE_DATA = "RECEIVE_DATA";
export const REQUESTING_DATA = "REQUESTING_DATA";
export const SET_BOTTOM_DATA = "SET_BOTTOM_DATA";
export const SET_BROWSER_TYPE = "SET_BROWSER_TYPE";
export const SET_TOP_ISOLATION_FLAG = "SET_TOP_ISOLATION_FLAG";
export const SET_MIDDLE_ISOLATION_FLAG = "SET_MIDDLE_ISOLATION_FLAG";
export const SET_MIDDLE_DATA = "SET_MIDDLE_DATA";

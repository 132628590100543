import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
// import _ from "underscore";
// import { saveAs } from "file-saver";

import { clickedEnter } from "@exovera/helpers";

import Select from "@exovera/select";
import { TripleToggle } from "components";

import vars, { focusStyle } from "@exovera/narrative-tracker-variables";
import styled from "styled-components";
import { clearAfter, smallCaps } from "@exovera/style-mixins";

// ------- Styles -------
const Root = styled.section`
	position: sticky;
	top: -1px;
	padding: 6px 15px 5px;
	text-align: right;
	background-color: ${vars.charcoal_2_5};
	box-shadow: 0px 4px 14px -3px rgba(0, 0, 0, 0.75);
	z-index: 100;
	${clearAfter()};
	@media all and (max-width: 900px) {
		text-align: center;
		padding: 6px 0 5px;
	}
`;
const Title = styled.h1`
	float: left;
	font-size: 24px;
	color: ${vars.sky_1};
	${vars.mainfontLight};
	margin: -11px 0 0 3px; /* margin-top 9px before transform */
	padding: 8px 12px;
	transform: translate3d(0, ${(props) => (props.pushOut ? 0 : "20px")}, 0);
	opacity: ${(props) => (props.pushOut ? 0 : 1)};
	pointer-events: ${(props) => (props.pushOut ? "none" : "all")};
	transition: ${(props) => (props.noTransform ? "none" : "transform 350ms linear 50ms, opacity 350ms ease-out 50ms")};
	cursor: ${(props) => (props.clickable ? "pointer" : "text")};
	${(props) => props.showFocus && focusStyle()};
	&:after {
		content: "";
		display: block;
		border-bottom: ${(props) => (props.clickable ? `solid 1px ${vars.seal_3}` : "solid 1px transparent")};
		transition: border-color 350ms ease-out 50ms;
	}
	&:hover {
		color: ${(props) => (props.clickable ? vars.sky_0_5 : vars.sky_1)};
	}
	@media all and (max-width: 900px) {
		float: none;
		margin: -10px 0 30px 0; /* margin: 10px 0 10px before transform */
		font-size: 22px;
	}
	@media all and (max-width: 380px) {
		font-size: 18px;
	}
`;

const SelectBox = styled.div`
	display: inline-block;
	margin: 0;
	width: 120px;
	vertical-align: middle;
	@media all and (max-width: 380px) {
		width: 95px;
		margin-left: 10px;
	}
`;
const TitleReplacementToggle = styled.div`
	position: absolute;
	width: ${(props) => props.theWidth}px;
	left: ${(props) => (props.center ? `calc(50% - ${props.theWidth / 2}px)` : "30px")};
	padding-bottom: 6px;
	top: ${(props) => (props.center ? 16 : 20)}px;
	transform: translate3d(0, ${(props) => (props.show ? 0 : "20px")}, 0);
	opacity: ${(props) => (props.show ? 1 : 0)};
	pointer-events: ${(props) => (props.show ? "all" : "none")};
	transition: transform 350ms linear 50ms, opacity 350ms ease-out 50ms;
`;
const ToggleBox = styled.div`
	width: ${(props) => props.theWidth}px;
	display: inline-block;
	padding-bottom: 6px;
	margin-top: 7px;
	margin-right: 20px;
	vertical-align: middle;
	@media all and (max-width: 900px) {
		margin-right: 0;
	}
`;

const FiltersTitle = styled.p`
	margin-bottom: ${(props) => props.pushUp || 0}px;
	margin-left: ${(props) => props.pushLeft || 0}px;
	text-align: left;
	${smallCaps(vars.mainfontBold)};
	font-size: 11px;
	color: ${vars.sky_1};
`;

// FLAG_DOWNLOAD_MOCK_CALL
// // Call this to download "state" in a .js file
// const downloadState = (params) => {
// 	const { state } = params;
// 	console.log("download state", state);
// 	// Seems to only work on FireFox
// 	const blob = new Blob([JSON.stringify(state)], {
// 		type: "application/json",
// 		name: "stateSnapshot.js",
// 	});
// 	console.log("downloadState", blob);
// 	saveAs(blob, "baseSnapshot.js");
// };

// ------- Component -------
const Controls = (props) => {
	const {
		clickFilterToggle,
		clickTopToggle,
		currentDateObj,
		currentFilterId,
		dateRanges,
		dateSelect,
		filters,
		isStuck,
		openMethodology,
		replaceTitleWithToggle,
		showFocus,
		theRef,
		title,
		topToggle,
		topToggleActive,
		windowSize,
	} = props;
	const methodologyRef = useRef(null);

	const mobileSize = windowSize.width < 380;
	const mobileTogglePlacement = windowSize.width <= 900;

	return (
		<Root ref={theRef}>
			{/* FLAG_DOWNLOAD_MOCK_CALL */}
			{/* <Title onClick={() => downloadState({ state: props.dataForDownload })}>{title}</Title> */}
			<Title
				ref={methodologyRef}
				pushOut={replaceTitleWithToggle}
				noTransform={windowSize.width <= 900 && replaceTitleWithToggle}
				clickable={isStuck}
				role="button"
				tabIndex={isStuck ? "0" : "-1"}
				showFocus={showFocus}
				onKeyPress={(event) => {
					if (clickedEnter(event) && isStuck) {
						methodologyRef.current.blur();
						openMethodology();
					}
				}}
				onClick={() => {
					if (isStuck) {
						methodologyRef.current.blur();
						openMethodology();
					}
				}}
			>
				{title}
			</Title>

			<TitleReplacementToggle theWidth={mobileSize ? 284 : 302} show={replaceTitleWithToggle} center={mobileTogglePlacement}>
				<TripleToggle
					activeId={topToggleActive}
					backgroundColor={vars.darkGrey_4}
					buttonWidth={mobileSize ? 94 : 100}
					center={topToggle.right}
					click={clickTopToggle}
					height={mobileTogglePlacement ? 28 : 34}
					left={topToggle.left}
					right={topToggle.both}
					showFocus={showFocus}
					blockFocus={!replaceTitleWithToggle}
				/>
			</TitleReplacementToggle>

			<ToggleBox theWidth={mobileSize ? 314 : 332}>
				<FiltersTitle pushUp={4} pushLeft={8}>
					{`by ${filters.name}`}:
				</FiltersTitle>
				<TripleToggle
					activeId={currentFilterId}
					backgroundColor={vars.darkGrey_4}
					buttonWidth={mobileSize ? 104 : 110}
					center={filters.right}
					click={clickFilterToggle}
					height={28}
					left={filters.left}
					right={filters.both}
					showFocus={showFocus}
				/>
			</ToggleBox>

			<SelectBox>
				<FiltersTitle pushUp={4}>Date Range:</FiltersTitle>
				<Select
					buttonFontSize={windowSize.width > 385 ? 14 : 11}
					height={28}
					id="dateSelect" // for testing
					onOptionClick={(date) => dateSelect(date)}
					options={dateRanges}
					selectedOption={currentDateObj}
					showFocus={showFocus}
					// alignLefts
					// colors
					buttonBackgroundColor={vars.charcoal_1}
					buttonBackgroundColorHover={vars.charcoal_1_2}
					buttonTextColor={vars.sky_1}
					optionTextColor={vars.sky_2}
					optionsBackgroundColor={vars.darkGrey_2}
					optionHoverColor={vars.darkGrey_3}
				/>
			</SelectBox>
		</Root>
	);
};

Controls.defaultProps = {};

Controls.propTypes /* remove-proptypes */ = {
	clickFilterToggle: PropTypes.func.isRequired,
	clickTopToggle: PropTypes.func.isRequired,
	currentDateObj: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		// startDate: PropTypes.number.isRequired,
		// endDate: PropTypes.number.isRequired,
		// totalDays: PropTypes.number.isRequired,
	}).isRequired,
	currentFilterId: PropTypes.string.isRequired,
	dateRanges: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired
	).isRequired,
	dateSelect: PropTypes.func.isRequired,
	filters: PropTypes.shape({
		filtersArray: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				color: PropTypes.string,
				active: PropTypes.bool.isRequired,
			}).isRequired
		).isRequired,
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
	isStuck: PropTypes.bool.isRequired,
	openMethodology: PropTypes.func.isRequired,
	replaceTitleWithToggle: PropTypes.bool.isRequired,
	showFocus: PropTypes.bool.isRequired,
	theRef: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	topToggle: PropTypes.shape({
		left: PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		}).isRequired,
		right: PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		}).isRequired,
		both: PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	topToggleActive: PropTypes.string.isRequired,
	windowSize: PropTypes.shape({
		height: PropTypes.number.isRequired,
		width: PropTypes.number.isRequired,
	}).isRequired,
};

export default Controls;

import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";

import vars from "@exovera/narrative-tracker-variables";
import styled from "styled-components";
// import { clearAfter, smallCaps, transition } from "@exovera/style-mixins";
import { LoadingSpinner } from "@exovera/loading-spinner";
import Tooltip from "@exovera/tooltip";
import InfoTooltip from "@exovera/info-tooltip";
import Modal from "@exovera/modal";
import { higherContrast } from "@exovera/helpers";

import { Bottom, Controls, Footer, Middle, Top, Navbar } from "layout";
import { Methodology, Notification, TripleToggle } from "components";

// ----------- Custom Hooks ------------
const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

// ----------- Styles ------------
const Base = styled.div`
	background-color: ${(props) => props.bgColor};
	min-height: 100vh;
`;
const PatternDefsBox = styled.div`
	position: absolute;
	top: -100px;
`;
const AppPadding = styled.div`
	padding: 10px;
	margin-top: -94px;
	@media all and (max-width: 1100px) {
		padding: 0;
	}
`;
const StickyToggle = styled.div`
	position: sticky;
	visibility: ${(props) => (props.hide ? "hidden" : "visible")};
	top: -10px;
	margin: 30px auto 0;
	width: ${(props) => props.theWidth}px;
	z-index: 101;
	@media all and (max-width: 900px) {
		top: -18px;
	}
`;
const ToggleSpacer = styled.div`
	padding-top: 30px;
`;

const LoadingBackground = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: ${(props) => props.bgColor};
	z-index: 105;
`;
const LoadingSpinnerBox = styled.div`
	position: absolute;
	width: 100px;
	height: 100px;
	top: 50%;
	left: 50%;
	margin: -50px 0 0 -50px;
	overflow: hidden;
`;

// ----------- Component ------------
const AppLayout = (props) => {
	const {
		browserType,
		closeMethodology,
		darkUI,
		dataReady,
		infoTooltipBullets,
		infoTooltipHeader,
		infoTooltipOpen,
		infoTooltipPosition,
		methodologyOpen,
		showFocus,
		tagTooltipColor,
		tagTooltipPosition,
		tagTooltipOpen,
		tagTooltipText,
		topToggle,
		topToggleActive,
		clickTopToggle,
		windowSize,
	} = props;

	const methodologyXRef = useRef(null);
	// const prevMethodologyOpen = usePrevious(methodologyOpen);
	useEffect(
		() => {
			if (methodologyOpen) {
				methodologyXRef.current.focus();
			}
		},
		[methodologyOpen, methodologyXRef]
	);

	const [toggleHasArrived, setToggleHasArrived] = useState(false); // using toggleHasArrived instead of afterInitialRender
	const [controlsRef, controlsInView, controlsEntry] = useInView({ threshold: 1 });
	const [toggleRef, toggleInView, toggleEntry] = useInView({ threshold: 1 });
	const toggleIsStuck = toggleHasArrived && !toggleInView;
	useEffect(() => {
		if (toggleInView) {
			setToggleHasArrived(true);
		}
	});

	const mobileSize = windowSize.width < 380;
	const showStaticToggle = windowSize.width < 1300;

	return (
		<Base className={`${!showFocus ? "hideFocus" : ""}`} bgColor={vars.black_3}>
			<PatternDefsBox>
				{/* <svg height="10" width="10" xmlns="http://www.w3.org/2000/svg" version="1.1">
					<defs>
						<pattern id="svgOpposeStripe" patternUnits="userSpaceOnUse" width="10" height="10">
							<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
								<path d="M-1,1 l2,-2 M0,10 l10,-10 M9,11 l2,-2" stroke="#1f273d" strokeWidth="3" />
							</svg>
						</pattern>
					</defs>
				</svg> */}
				<svg height="4" width="4" xmlns="http://www.w3.org/2000/svg" version="1.1">
					<defs>
						<pattern id="svgOpposeStripe" patternUnits="userSpaceOnUse" width="4" height="4">
							<svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4">
								<path fill="#1f273d" d="M1 3h1v1H1V3zm2-2h1v1H3V1z" fillOpacity="0.6" />
							</svg>
						</pattern>
					</defs>
				</svg>
			</PatternDefsBox>

			{!dataReady ? (
				<LoadingBackground bgColor={vars.black_2}>
					<LoadingSpinnerBox>
						<LoadingSpinner />
					</LoadingSpinnerBox>
				</LoadingBackground>
			) : (
				<Fragment>
					{browserType === "ie" && (
						<Notification
							notification={{
								title: "Please upgrade your browser for the full experience",
								notificationMessage: "Internet Explorer is outdated and is no longer supported. Please update to Edge, FireFox, or Chrome.",
							}}
							// zIndex={10}
						/>
					)}

					<Navbar />

					<Controls
						theRef={controlsRef}
						isStuck={!controlsInView && toggleHasArrived}
						replaceTitleWithToggle={toggleIsStuck && showStaticToggle}
					/>

					<StickyToggle ref={toggleRef} theWidth={mobileSize ? 284 : 302} hide={showStaticToggle} isStuck={toggleIsStuck}>
						<ToggleSpacer>
							<TripleToggle
								left={topToggle.left}
								center={topToggle.right}
								right={topToggle.both}
								activeId={topToggleActive}
								click={clickTopToggle}
								height={toggleIsStuck && windowSize.width < 900 ? 28 : 34}
								buttonWidth={mobileSize ? 94 : 100}
								showFocus={showFocus}
								backgroundColor={vars.darkGrey_4}
							/>
						</ToggleSpacer>
					</StickyToggle>

					<AppPadding>
						<Top showStaticToggle={showStaticToggle} />
						<Middle />
						<Bottom />
						<Footer />
					</AppPadding>

					<Tooltip
						backgroundColor={tagTooltipColor}
						elementPosition={tagTooltipPosition}
						id="tagTooltip" // for testing
						open={tagTooltipOpen}
						text={tagTooltipText}
						textColor={
							tagTooltipColor
								? higherContrast({
										color1: "#000000",
										color2: "#ffffff",
										background: tagTooltipColor,
								  })
								: "#000000"
						}
						windowSize={windowSize}
						fast
					/>

					<InfoTooltip
						id="infoTooltip"
						backgroundColor={darkUI ? vars.grey_3_5 : "#fff"}
						bulletColor={darkUI ? vars.sky_2 : vars.text_2_5}
						color={darkUI ? vars.sky_1 : vars.text_2}
						iconFill={darkUI ? vars.sky_2 : vars.grey_2_5}
						open={infoTooltipOpen}
						elementPosition={infoTooltipPosition}
						text={infoTooltipHeader}
						bullets={infoTooltipBullets}
						width={300}
						windowSize={windowSize}
					/>

					<Modal
						id="methodologyModal"
						backgroundClick={() => closeMethodology()}
						backgroundColor={darkUI ? vars.grey_4 : vars.blueGrey_1}
						direction="top"
						height={500}
						open={methodologyOpen}
						showFocus={showFocus}
						width={700}
						xClick={() => closeMethodology()}
						xColor={darkUI ? vars.sky_1 : vars.text_3}
						xRef={methodologyXRef}
					>
						<Methodology />
					</Modal>
				</Fragment>
			)}
		</Base>
	);
};

AppLayout.propTypes /* remove-proptypes */ = {
	browserType: PropTypes.string.isRequired,
	clickTopToggle: PropTypes.func.isRequired,
	closeMethodology: PropTypes.func.isRequired,
	darkUI: PropTypes.bool.isRequired,
	dataReady: PropTypes.bool.isRequired,
	infoTooltipBullets: PropTypes.arrayOf(PropTypes.string).isRequired,
	infoTooltipHeader: PropTypes.string.isRequired,
	infoTooltipOpen: PropTypes.bool.isRequired,
	infoTooltipPosition: PropTypes.shape({
		left: PropTypes.number.isRequired,
		top: PropTypes.number.isRequired,
	}).isRequired,
	methodologyOpen: PropTypes.bool.isRequired,
	showFocus: PropTypes.bool.isRequired,
	tagTooltipColor: PropTypes.string.isRequired,
	tagTooltipPosition: PropTypes.shape({
		left: PropTypes.number.isRequired,
		top: PropTypes.number.isRequired,
	}).isRequired,
	tagTooltipOpen: PropTypes.bool.isRequired,
	tagTooltipText: PropTypes.string.isRequired,
	topToggle: PropTypes.shape({
		left: PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		}).isRequired,
		right: PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		}).isRequired,
		both: PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	topToggleActive: PropTypes.string.isRequired,
	windowSize: PropTypes.shape({
		height: PropTypes.number.isRequired,
		width: PropTypes.number.isRequired,
	}).isRequired,
};

export default AppLayout;

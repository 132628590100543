import React from "react";
import { connect } from "react-redux";
import Footer from "./Footer.jsx";

// mapStateToProps is a react-redux specific function
// that injects the redux store, after filters. REQUIRED
//    state = reduxStore
//    ownProps = props passed to container Component (Not Meteor data)
const mapStateToProps = (state, ownProps) => {
	// console.log(state);
	// console.log(ownProps)
	return {
		showFocus: state.ui.showFocus,
		socialTitle: state.data.socialTitle,
		socialText: state.data.socialText,
		socialHashtags: state.data.socialHashtags,
		socialTwitter: state.data.socialTwitter,
	};
};

// mapDispatchToProps is a react-redux specific
// function that binds a function into the props
// Default is: dispatch => ({ dispatch }), to this isn't necessary in simple uses
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		// openMethodology: () => {
		// 	dispatch(openMethodology());
		// },
	};
};

// const mergeProps = ( stateProps, actionProps, parentProps ) => ({
// ...stateProps,
// ...actionProps,
// ...parentProps
// });

// Combines everything, including Meteor data
const FooterContainer = connect(
	mapStateToProps,
	mapDispatchToProps
	// mergeProps
)(Footer);

export default FooterContainer;
